import React, { useState, useRef, useEffect } from "react";
import BillDotCom from "../../assets/BillCom.svg";

function useOutsideAlerter(ref, onClickOutside) {
  useEffect(() => {
    function handleOutsideClick(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [ref]);
}

function ContextModal({ show, hide, id }) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => hide());

  const sources = ["Source A", "Source B", "Source C"];

  return show ? (
    <div
      ref={wrapperRef}
      className="absolute p-6 items-center top-8 shadow-md rounded-md z-10 flex flex-col bg-white"
    >
      <p className="font-semibold mb-2">Context</p>

      <a
        href="https://app02.us.bill.com/"
        target={"_blank"}
        className="w-20 my-2 border-slate-200 border-[1px] hover:border-slate-400 cursor-pointer rounded-md"
      >
        <img src={BillDotCom} />
      </a>
    </div>
  ) : null;
}

export default ContextModal;
