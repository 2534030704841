import axios from "axios";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { showError, showSuccess } from "../../common/showPopup";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Img,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { HStack, VStack } from "@chakra-ui/react";
import DbContext from "./DbContext";
import { CopyBlock, dracula } from "react-code-blocks";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import "./Integrations.css";
import redDot from "../../assets/redDot.svg";
import greenDot from "../../assets/greenDot.svg";
import TableContext from "./TableContext";

// Register the plugin
registerPlugin(FilePondPluginFileValidateType);

const snowFlakeInstructionsText = `-- change role to ACCOUNTADMIN
use role ACCOUNTADMIN;

-- create role for layerup
create role if not exists layerup_role;
grant role layerup_role to role SYSADMIN;

-- create a user for layerup
create user if not exists layerup_user
password = 'enter password here';
grant role layerup_role to user layerup_user;
alter user layerup_user set default_role = layerup_role default_warehouse = 'layerup_wh';

-- change role
use role SYSADMIN;

-- create a warehouse for layerup
create warehouse if not exists layerup_wh

-- set the size based on your dataset
warehouse_size = 'x-small' warehouse_type = standard auto_suspend = 1200 auto_resume = true initially_suspended = true;
grant all privileges on warehouse layerup_wh to role layerup_role;

-- grant read only database access (repeat for all database/schemas)
grant usage on database <database> to role layerup_role;
grant usage on schema <database>.<schema> to role layerup_role;
grant select on all tables in schema <database>.<schema> to role layerup_role;`;

const ConnectionModal = ({ show, onClose, name, parentOnDone }) => {
  const account = useRef();
  const username = useRef();
  const password = useRef();
  const application = useRef();
  const host = useRef();
  const port = useRef();
  const database = useRef();

  const [loading, setLoading] = useState(false);
  const [accountInvalid, setAccountInvalid] = useState(false);
  const [usernameInvalid, setUsernameInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [applicationInvalid, setApplicationInvalid] = useState(false);
  const [hostInvalid, setHostInvalid] = useState(false);
  const [portInvalid, setPortInavlid] = useState(false);
  const [databaseInvalid, setDatabaseInvalid] = useState(false);
  const [files, setFiles] = useState([]);
  const [tableContexts, setTableContexts] = useState([]);

  useEffect(() => {
    setFiles([]);
  }, [show]);

  const handleFileUpload = (fileItems) => {
    setFiles(fileItems.map((fileItem) => fileItem.file));
  };
  const onConnect = () => {
    let error = false;

    if (name === "BigQuery" && (!files || !files.length)) {
      showError("Please upload your json keyfile");
      return;
    }

    if (name !== "BigQuery" && !username.current.value) {
      setUsernameInvalid(true);
      error = true;
    }

    if (name !== "BigQuery" && !password.current.value) {
      setPasswordInvalid(true);
      error = true;
    }

    if (
      name === "PostgreSQL" ||
      name === "Redshift" ||
      name === "MongoDB" ||
      name === "MySQL"
    ) {
      if (!host.current.value) {
        setHostInvalid(true);
        error = true;
      }

      if (!port?.current?.value && name !== "MongoDB") {
        setPortInavlid(true);
        error = true;
      }

      if (!database.current.value) {
        setDatabaseInvalid(true);
        error = true;
      }
    } else if (name !== "BigQuery") {
      if (!account.current.value) {
        setAccountInvalid(true);
        error = true;
      }

      if (!application.current.value) {
        setApplicationInvalid(true);
        error = true;
      }
    }

    if (error) {
      return;
    }

    setLoading(true);

    let endpoint = "/integrations/connect-snowflake";

    let params = {
      username: username?.current?.value,
      password: password?.current?.value,
    };

    let headers = {};

    if (name === "PostgreSQL" || name === "Redshift" || name === "MySQL") {
      endpoint = "/integrations/connect-postgresql";

      params.host = host.current.value;
      params.port = port.current.value;
      params.database = database.current.value;

      if (name === "MySQL") {
        endpoint = "/integrations/connect-mysql";
      }

      if (name === "Redshift") {
        params.kind = "REDSHIFT";
      }
    } else if (name === "MongoDB") {
      endpoint = "/integrations/connect-mongodb";
      params.host = host.current.value;
      params.database = database.current.value;
    } else if (name === "BigQuery") {
      endpoint = "/integrations/connect-bigquery";
      const formData = new FormData();
      formData.append("keyfile", files[0]);

      headers = {
        "Content-Type": "multipart/form-data",
      };

      params = formData;
    } else {
      params.account = account.current.value;
      params.application = application.current.value;
    }

    axios
      .post(process.env.REACT_APP_SERVER_URL + endpoint, params, headers)
      .then((res) => {
        if (res.data.err) {
          showError(res.data.err);
        } else {
          setTableContexts(res.data.databases);
        }

        setLoading(false);
      })
      .catch((err) => {
        showError("Sorry, something went wrong");
        setLoading(false);
      });
  };

  const onSaveTableContexts = () => {
    setLoading(true);
    axios
      .patch(process.env.REACT_APP_SERVER_URL + "/integrations/update-tables", {
        databases: tableContexts,
      })
      .then((res) => {
        showSuccess(`Your ${name} account was connected`);

        if (parentOnDone) {
          parentOnDone();
        } else {
          onClose();
        }

        setLoading(false);
      })
      .catch((err) => {
        showError("Sorry, something went wrong");
        setLoading(false);
      });
  };

  const bigQueryInstructions = () => {
    return (
      <div className="bg-black text-white">
        <h2 className="text-xl font-bold">Instructions</h2>
        <p>
          Please only provide <b>READ</b> access
        </p>
        <p className="mt-2">
          Please note that network policies must allow access from all IP
          addresses
        </p>
        <ul className="list-disc">
          <li className="my-2">
            In your GCP console, navigate to 'IAM & Admin' and click 'Service
            Accounts'.
          </li>

          <li className="my-2">Click 'Create Service Account'.</li>

          <li className="my-2">
            Grant the service account the <b>BigQuery Data Viewer</b> and{" "}
            <b>BigQuery Job User</b> roles.
          </li>

          <li className="my-2">
            Click on your newly created service account and then click keys.
          </li>

          <li className="my-2">
            Click 'Add Key', 'Create new key' and download a JSON key file.
          </li>
        </ul>
      </div>
    );
  };

  const mySqlInstructions = () => {
    return (
      <div className="bg-black text-white">
        <h2 className="text-xl font-bold">Instructions</h2>
        <p>
          Please only provide <b>READ</b> access
        </p>
        <p className="mt-2">
          Please note that network policies must allow access from all IP
          addresses
        </p>

        <div className="my-3">
          <p>Create a new user</p>
          <CopyBlock
            text={`CREATE USER 'layerup_user'@'%' IDENTIFIED BY 'password';`}
            language={"SQL"}
            showLineNumbers={false}
            theme={dracula}
          />
        </div>

        <div className="my-3">
          <p>Allow user to connect to database</p>
          <CopyBlock
            text={"GRANT CONNECT ON database_name.* to 'layerup_user'@'%';"}
            language={"SQL"}
            showLineNumbers={false}
            theme={dracula}
          />
        </div>

        <div className="my-3">
          <p>Allow user to read all tables</p>
          <CopyBlock
            text={"GRANT SELECT ON database_name.* TO 'layerup_user'@'%';"}
            language={"SQL"}
            showLineNumbers={false}
            theme={dracula}
          />
        </div>
      </div>
    );
  };

  const postgreInstructions = () => {
    return (
      <div className="bg-black text-white">
        <h2 className="text-xl font-bold">Instructions</h2>
        <p>
          Please only provide <b>READ</b> access
        </p>
        <p className="mt-2">
          Please note that network policies must allow access from all IP
          addresses
        </p>

        <div className="my-3">
          <p>Create a new user</p>
          <CopyBlock
            text={`CREATE USER layerup_user WITH ${
              name === "Redshift" ? "" : "ENCRYPTED"
            } PASSWORD '<password>';`}
            language={"SQL"}
            showLineNumbers={false}
            theme={dracula}
          />
        </div>

        <div className="my-3">
          <p>
            {name === "Redshift"
              ? "Allow user to access schema"
              : "Allow user to connect to database"}
          </p>
          <CopyBlock
            text={
              name === "Redshift"
                ? "GRANT USAGE ON SCHEMA public to layerup_user;"
                : "GRANT CONNECT ON DATABASE <database_name> to layerup_user;"
            }
            language={"SQL"}
            showLineNumbers={false}
            theme={dracula}
          />
        </div>

        {name === "PostgreSQL" && (
          <div className="my-3">
            <p>Connect to database</p>
            <CopyBlock
              text={"\\c <database_name>"}
              language={"SQL"}
              showLineNumbers={false}
              theme={dracula}
            />
          </div>
        )}

        <div className="my-3">
          <p>Allow user to read all tables</p>
          <CopyBlock
            text={
              "GRANT SELECT ON ALL TABLES IN SCHEMA public TO layerup_user;"
            }
            language={"SQL"}
            showLineNumbers={false}
            theme={dracula}
          />
        </div>
      </div>
    );
  };

  const mongoInstructions = () => {
    return (
      <div>
        <h2 className="text-xl font-bold">Instructions</h2>
        <p>
          Please only provide <b>READ</b> access
        </p>
        <p className="mt-2 mb-8">
          Please note that network policies must allow access from all IP
          addresses
        </p>
        <ul className="list-disc">
          <li className="my-2">
            In MongoDB Atlas, under security, select 'Database Access'.
          </li>

          <li className="my-2">Click 'Add New Database User'.</li>

          <li className="my-2">
            Create a new user called <b>layerup_user</b> using the password
            authentication method.
          </li>

          <li className="my-2">
            Under 'Database User Privileges', select 'Only read any database'
            under 'Built-in Role'.
          </li>

          <li className="my-2">
            Under 'Restrict Access', select the database you wish to connect to.
          </li>
        </ul>
      </div>
    );
  };

  const snowflakeInstructions = () => {
    return (
      <div>
        <h2 className="text-xl font-bold">Instructions</h2>
        <p>
          Please only provide <b>READ</b> access
        </p>
        <p className="mt-2">
          Please note that network policies must allow access from all IP
          addresses
        </p>

        <div className="my-3">
          <CopyBlock
            text={snowFlakeInstructionsText}
            language={"SQL"}
            showLineNumbers={false}
            theme={dracula}
          />
        </div>
      </div>
    );
  };

  const getInstructions = () => {
    if (name === "PostgreSQL" || name === "Redshift") {
      return postgreInstructions();
    } else if (name === "Snowflake") {
      return snowflakeInstructions();
    } else if (name === "MongoDB") {
      return mongoInstructions();
    } else if (name === "MySQL") {
      return mySqlInstructions();
    } else if (name === "BigQuery") {
      return bigQueryInstructions();
    } else {
      return <div>None</div>;
    }
  };

  const bigQueryUpload = () => {
    return (
      <div className="flex items-center justify-center">
        <div className="w-full">
          <FormLabel>Upload BigQuery JSON Keyfile</FormLabel>
          <FilePond
            className="filepond block w-full"
            files={files}
            name="keyfile"
            allowMultiple={false}
            onupdatefiles={handleFileUpload}
            acceptedFileTypes={["application/json"]}
            fileValidateTypeLabelExpectedTypes="Please upload a .json file"
            labelIdle='Drag & Drop your keyfile or <span class="filepond--label-action">Browse</span>'
          />
        </div>
      </div>
    );
  };

  const getForm = () => {
    if (name === "BigQuery") {
      return bigQueryUpload();
    }

    return (
      <div className="pl-4 ">
        <FormControl isInvalid={usernameInvalid} id="username" isRequired>
          <FormLabel>Username</FormLabel>
          <Input
            borderColor="#4A4A4A"
            defaultValue={name === "PostgreSQL" ? "layerup_user" : ""}
            ref={username}
            type="text"
          />
          <FormErrorMessage>Username is required</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={passwordInvalid} id="password" isRequired>
          <FormLabel>Password</FormLabel>
          <Input borderColor="#4A4A4A" ref={password} type="password" />
          <FormErrorMessage>Password is required</FormErrorMessage>
        </FormControl>
        {name === "PostgreSQL" ||
        name === "Redshift" ||
        name === "MongoDB" ||
        name === "MySQL" ? (
          <FormControl isInvalid={hostInvalid} id="host" isRequired>
            <FormLabel>Host</FormLabel>
            <Input
              borderColor="#4A4A4A"
              placeholder={
                name === "MongoDB" ? "<cluster-name>.<uid>.mongodb.net" : ""
              }
              ref={host}
              type="text"
            />
            <FormErrorMessage>Host is required</FormErrorMessage>
          </FormControl>
        ) : null}

        {name === "PostgreSQL" ||
        name === "Redshift" ||
        name === "MongoDB" ||
        name === "MySQL" ? (
          <FormControl isInvalid={databaseInvalid} id="database" isRequired>
            <FormLabel>Database</FormLabel>
            <Input borderColor="#4A4A4A" ref={database} type="text" />
            <FormErrorMessage>Database is required</FormErrorMessage>
          </FormControl>
        ) : null}

        {name === "PostgreSQL" || name === "Redshift" || name === "MySQL" ? (
          <FormControl isInvalid={portInvalid} id="port" isRequired>
            <FormLabel>Port</FormLabel>
            <Input
              borderColor="#4A4A4A"
              defaultValue={
                name === "PostgreSQL" ? "5432" : name === "MySQL" ? "3306" : ""
              }
              ref={port}
              type="text"
            />
            <FormErrorMessage>Port is required</FormErrorMessage>
          </FormControl>
        ) : null}

        {name === "Snowflake" ? (
          <FormControl isInvalid={accountInvalid} id="account" isRequired>
            <FormLabel>Account</FormLabel>
            <Input
              placeholder="<Organization Id>-<Account Id>"
              borderColor="#4A4A4A"
              ref={account}
              type="text"
            />
            <FormErrorMessage>Account is required</FormErrorMessage>
          </FormControl>
        ) : null}

        {name === "Snowflake" ? (
          <FormControl
            isInvalid={applicationInvalid}
            id="application"
            isRequired
          >
            <FormLabel>Application</FormLabel>
            <Input borderColor="#4A4A4A" ref={application} type="text" />
            <FormErrorMessage>Application is required</FormErrorMessage>
          </FormControl>
        ) : null}
      </div>
    );
  };

  return (
    <Modal size={"4xl"} isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="bg-black">
        <ModalHeader
          borderRadius="10px 10px 0 0"
          className="  bg-black border-t border-l border-r border-[#4A4A4A] text-white "
        >
          Connect {name}
        </ModalHeader>

        <ModalCloseButton className="text-white" />
        <ModalBody
          bg="black"
          className=" text-white border-l border-r border-[#4A4A4A] "
        >
          {tableContexts && tableContexts.length ? (
            <TableContext
              updatedContexts={tableContexts}
              setUpdatedContexts={setTableContexts}
            />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-4 ">
              {getInstructions()}
              {getForm()}
            </div>
          )}
        </ModalBody>
        <ModalFooter
          borderRadius="0px 0px 10px 10px"
          bg="black"
          className=" text-white border-b border-l border-r border-[#4A4A4A] "
        >
          <Button
            isLoading={loading}
            loadingText="Adding.."
            onClick={tableContexts.length ? onSaveTableContexts : onConnect}
            sx={{
              backgroundColor: "#2563eb",
              color: "white",
              borderRadius: "md",
              _hover: {
                backgroundColor: "#1849b4",
              },
              _active: {
                backgroundColor: "#1849b4",
              },
              _focus: {
                boxShadow: "none",
              },
            }}
          >
            {loading
              ? "Please wait.."
              : tableContexts.length
              ? "Save"
              : "Connect"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const AreYouSureModal = ({ name, show, onClose, type, setConnected }) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/integrations/remove", {
        kind: type,
      })
      .then(() => {
        setConnected(false);
        onClose();
      })
      .catch((err) => {
        showError(`Sorry, your ${name} could not be disconnected`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="black">
        <ModalHeader
          borderRadius="10px 10px 0 0"
          className="bg-black border-t border-l border-r border-[#4A4A4A] text-white"
        >
          Remove {name}
        </ModalHeader>
        <ModalCloseButton className="text-white" />

        <ModalBody className="bg-black  border-l border-r border-[#4A4A4A] text-white">
          {" "}
          Are you sure you want to remove your <b>{name}</b> connection?
        </ModalBody>

        <ModalFooter
          borderRadius="0px 0px 10px 10px"
          className="bg-black border-b border-l border-r border-[#4A4A4A] text-white"
        >
          <button onClick={onClose} className="mr-4 blackButton">
            Cancel
          </button>
          <Button
            onClick={handleConfirm}
            isLoading={loading}
            loadingText="Loading..."
            bg={"red.400"}
            color={"white"}
            _hover={{
              bg: "red.500",
            }}
          >
            Remove
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

function ConnectDB({
  type,
  hideButton,
  overrideShowModal,
  parentOnClose,
  parentOnDone,
}) {
  const [connected, setConnected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  let name = "Snowflake";

  if (type === "POSTGRESQL") {
    name = "PostgreSQL";
  } else if (type === "REDSHIFT") {
    name = "Redshift";
  } else if (type === "MONGODB") {
    name = "MongoDB";
  } else if (type === "MYSQL") {
    name = "MySQL";
  } else if (type === "BIGQUERY") {
    name = "BigQuery";
  }

  useEffect(() => {
    setShowModal(overrideShowModal);
  }, [overrideShowModal]);

  useEffect(() => {
    setLoading(true);
    if (!hideButton) {
      axios
        .get(process.env.REACT_APP_SERVER_URL + "/integrations/db-status", {
          params: {
            kind: type,
          },
        })
        .then((res) => {
          setConnected(res.data.connected);
        })
        .catch((err) => {
          showError("Sorry, something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [showModal]);

  return (
    <>
      <AreYouSureModal
        name={name}
        show={showConfirm}
        onClose={() => setShowConfirm(false)}
        type={type}
        setConnected={setConnected}
      />
      <ConnectionModal
        name={name}
        show={showModal}
        parentOnDone={parentOnDone}
        onClose={() => {
          if (parentOnClose) {
            parentOnClose();
          }
          setShowModal(false);
        }}
      />

      {hideButton ? null : (
        <div className="my-6 mx-auto blackDiv w-[800px] p-5">
          <div className="flex flex-row items-center justify-between">
            <button
              disabled={loading}
              className="blackButton  text-white   "
              borderRadius="50px"
              isLoading={loading}
              loadingText={`${name} Connection`}
              onClick={() => {
                if (connected) {
                  setShowConfirm(true);
                } else {
                  setShowModal(true);
                }
              }}
            >
              {connected ? (
                <div className="flex">
                  <img className="mr-2" src={greenDot} alt="Icon"></img>
                  <h3>{name} is connected</h3>
                </div>
              ) : (
                <div className="flex">
                  <img className="mr-2" src={redDot} alt="Icon"></img>
                  <h3>{name} not connected</h3>
                </div>
              )}
            </button>

            {connected && (
              <button
                className="blackButton text-white"
                disabled={loading || !connected}
                onClick={() => setShowConfig(!showConfig)}
              >
                {showConfig ? "Hide" : "Show"} configuration
              </button>
            )}
          </div>

          {connected ? <DbContext type={type} open={showConfig} /> : null}
        </div>
      )}
    </>
  );
}

export default ConnectDB;
