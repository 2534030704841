import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Typeahead from "../Shared/Typeahead";

export const savePrompt = async (prompt, alias) => {
  try {
    await axios.post(
      process.env.REACT_APP_SERVER_URL + "/questions/savePrompt",
      { promptQuestion: prompt, alias }
    );
    toast.success("Prompt Saved Successfully");
  } catch (error) {
    console.log("error in saving prompt", error);
    toast.error("Error in saving prompt");
  }
};

const DetailedPromptSavingModal = ({ isOpen, onClose, onSelect }) => {
  const [toCreateNew, setToCreateNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newPrompt, setNewPrompt] = useState("");
  const [newAlias, setNewAlias] = useState("");
  const [prompts, setPrompts] = useState();

  const getAllSavedPrompts = async () => {
    setIsLoading(true);
    try {
      let out = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/questions/allSavedPrompts"
      );
      setPrompts(out.data.prompts || []);
    } catch (error) {
      console.log("error in getting prompts", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllSavedPrompts();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          containerProps={{
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: "2rem",
            paddingBottom: "2rem",
          }}
        >
          <ModalHeader className="text-white">Saved prompts</ModalHeader>
          <ModalCloseButton className="text-white" />
          <ModalBody>
            <div className="flex flex-col gap-5 items-start">
              <Typeahead
                options={isLoading ? [] : prompts}
                onClick={() => getAllSavedPrompts()}
                placeholder="Search with Prompt or Aliases"
                onSelect={(option) => {
                  onSelect(option);
                  console.log("selectedoption", option);
                }}
              />
              <button onClick={() => setToCreateNew(true)}>
                <div className="flex text-white gap-5 items-center">
                  <p>Create & Save a Prompt</p>
                </div>
              </button>
              <input
                type="text"
                className="w-full p-3 border bg-transparent rounded-md text-white"
                placeholder="Enter Alias (optional)"
                required={false}
                onChange={(e) => setNewAlias(e.target.value)}
              />
              <input
                type="text"
                className="w-full p-3 border bg-transparent rounded-md text-white"
                placeholder="Enter Prompt"
                onChange={(e) => setNewPrompt(e.target.value)}
              />
              <button
                className="p-1 border text-white w-full rounded-md"
                onClick={() => savePrompt(newPrompt, newAlias)}
              >
                Save Prompt
              </button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DetailedPromptSavingModal;
