import React, { useRef, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";

function ConnectBillingModal({ show, onClose }) {
  const token = useRef();

  const [loading, setLoading] = useState(false);
  const [tokenInvalid, setTokenInvalid] = useState(false);

  const onAdd = () => {};

  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Connect Stripe</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack spacing={4} marginY={"20px"}>
            <FormControl isInvalid={tokenInvalid} id="token" isRequired>
              <FormLabel>API Key</FormLabel>
              <Input ref={token} type="text" />
              <FormErrorMessage>API key is required</FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            loadingText="Adding.."
            bg={"blue.400"}
            color={"white"}
            _hover={{
              bg: "blue.500",
            }}
            onClick={onAdd}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConnectBillingModal;
