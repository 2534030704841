import React from "react";
import Chart from "react-apexcharts";

const ApexWrapper = ({
  className,
  chartType = "bar",
  xAxisData,
  yAxisData,
  y2AxisData,
}) => {
  const isPieOrDonut = chartType === "donut" || chartType === "pie";
  const apexData = {
    options: {
      labels: xAxisData,

      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        fontSize: "14px",
        fontFamily: "Helvetica, Arial",
        fontWeight: 400,
        formatter: undefined,
        inverseOrder: false,
        width: undefined,
        height: undefined,
        tooltipHoverFormatter: undefined,
        customLegendItems: [],
        offsetX: 0,
        offsetY: 0,
        labels: {
          colors: undefined,
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
        itemMargin: {
          horizontal: 5,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        id: "basic-bar",
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
      },
      plotOptions: {
        candlestick: {
          wick: {
            useFillColor: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: ["#fff"],
          },
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: ["#fff"],
          },
        },
        categories: xAxisData,
      },
    },
    series: isPieOrDonut
      ? yAxisData
      : [
          {
            data: yAxisData,
          },
          {
            data: y2AxisData || [],
          },
        ],
  };
  return (
    <>
      <Chart
        id={"chartContainer"}
        options={apexData.options}
        series={apexData.series}
        type={chartType}
        // width="500"
      />
    </>
  );
};

export default ApexWrapper;
