import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { showError } from "../../common/showPopup";
import axios from "axios";

function UpgradePopup({ show, onClose }) {
  const [loading, setLoading] = useState(false);

  const onUpgrade = () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/plans/create-checkout-session")
      .then((res) => {
        if (res.data?.url) {
          window.location.href = res.data.url;
        }
      })
      .catch(() => {
        showError("Sorry, something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal size={"lg"} isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="black">
        <ModalHeader
          borderRadius="10px 10px 0 0"
          className="bg-black border-t border-l border-r border-[#4A4A4A] text-white"
        >
          Upgrade
        </ModalHeader>
        <ModalCloseButton className="text-white" />

        <ModalBody className="bg-black  border-l border-r border-[#4A4A4A] text-white">
          <div className="mb-12">
            <span className="text-lg">
              You've used up all 25 queries of the free plan. Please upgrade to
              continue using Layerup!
            </span>

            <div className="flex items-center justify-center mt-12">
              <div className="p-6 rounded-lg bg-white text-black flex items-center justify-center flex-col">
                <p className="font-bold text-xl">$</p>
                <p className="font-bold text-4xl">150</p>
                <p className="font-bold text-xl">/ month</p>
                <p className="mt-4">Unlimated queries</p>

                <Button
                  isLoading={loading}
                  onClick={onUpgrade}
                  sx={{
                    backgroundColor: "#2563eb",
                    color: "white",
                    borderRadius: "md",
                    _hover: {
                      backgroundColor: "#1849b4",
                    },
                    _active: {
                      backgroundColor: "#1849b4",
                    },
                    _focus: {
                      boxShadow: "none",
                    },
                  }}
                  className="w-full mt-6"
                >
                  Upgrade
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          borderRadius="0px 0px 10px 10px"
          className="bg-black border-b border-l border-r border-[#4A4A4A] text-white"
        ></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UpgradePopup;
