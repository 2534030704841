import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Progress,
} from "@chakra-ui/react";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { showError } from "../../common/showPopup";
import "./Chat.css";

function UploadModal({ show, onClose }) {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleClearData = async () => {
    setUploadProgress(0);
    setLoading(false);
    setIsUploading(false);
    setSelectedFile(null);
  };

  const handleFileUpload = async () => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const res = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/integrations/uploadcsv",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            console.log("progress", progressEvent.loaded, progressEvent.total);
            setUploadProgress(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          },
        }
      );
    } catch (error) {
      console.log("error_in_fileupload", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="black">
        <ModalHeader
          borderRadius="10px 10px 0 0"
          className="bg-black border-t border-l border-r border-[#4A4A4A] text-white"
        >
          Upload
        </ModalHeader>
        <ModalCloseButton className="text-white" />

        <ModalBody className="bg-black  border-l border-r border-[#4A4A4A] text-white">
          <Stack spacing={8} marginY={"10px"}>
            <input
              className="file-input"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              type="file"
              id="profile_pic"
              name="profile_pic"
            />
            <Button
              onClick={
                uploadProgress === 100 ? handleClearData : handleFileUpload
              }
              className="mb-5"
              isDisabled={selectedFile === null}
              isLoading={isUploading}
              loadingText="Uploading..."
              sx={{
                backgroundColor: "#2563eb",
                color: "white",
                borderRadius: "md",
                _hover: {
                  backgroundColor: "#1849b4",
                },
                _active: {
                  backgroundColor: "#1849b4",
                },
                _focus: {
                  boxShadow: "none",
                },
              }}
            >
              {uploadProgress === 100 ? "Upload Another File" : "Upload"}
            </Button>

            {isUploading && (
              <>
                <Progress
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "black",
                    border: "1px solid #4a4a4a",
                  }}
                  size="md"
                  value={uploadProgress}
                  hasStripe
                  color="green"
                />
                <p className="text-center text-white">
                  {isUploading ? (
                    <>Uploading... {uploadProgress.toFixed(1)}%</>
                  ) : (
                    "Uploaded"
                  )}
                </p>
              </>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter
          borderRadius="0px 0px 10px 10px"
          className="bg-black border-b border-l border-r border-[#4A4A4A] text-white"
        ></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UploadModal;
