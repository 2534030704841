import React, { useEffect } from "react";
import axios from "axios";
import AuditLog from "../../components/AuditLogs/AuditLog";
import { useState } from "react";

function AuditLogs() {
  const [isLoading, setLoading] = useState(false);
  const [logs, setLogs] = React.useState([]);

  useEffect(() => {
    getLogs();
  }, []);

  const getLogs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/integrations/allLogs"
      );
      console.log("response", response.data);
      const groupedLogs = response.data.logs.reduce((acc, log) => {
        const keyDate = new Date(log.trackDate).toDateString();
        if (!acc[keyDate]) {
          acc[keyDate] = [];
        }
        acc[keyDate].push(log);
        return acc;
      }, {});
      const diffGrouped = Object.keys(groupedLogs).map((key) => {
        return {
          mainDate: key,
          events: groupedLogs[key],
        };
      });
      setLogs(diffGrouped);
      console.log(response.data, groupedLogs, "log response data");
    } catch (error) {
      console.log(error, "error in logs");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {isLoading && <div className="text-xl text-white">Loading...</div>}
      <AuditLog logs={logs} />
    </div>
  );
}

export default AuditLogs;
