import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  List,
  ListItem,
} from "@chakra-ui/react";
import ContextModal from "./ContextModal";
import AuditIcon from "./AuditIcon";
import ExplainBtn from "./ExplainBtn";
import LiveBoardModal from "./LiveBoardModal";
import options from "../../assets/options.svg";
import ChartModal from "../ChartModal/ChartModal";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { toast } from "react-toastify";
import CenterPromptSavingModal from "../PromptSavingModals/CenterPromptSavingModal";

const Selector = ({ options }) => {
  const [option, setOption] = useState("");

  return (
    <div className="flex py-6 gap-3">
      {option
        ? `Thank you, you will be notified on ${option}`
        : options.map((option) => {
            return (
              <button
                onClick={() => setOption(option)}
                className="p-2 delay-100 select-none active:scale-95 hover:scale-105 transition-transform ease-in-out border-blue-500 border-2 rounded-md"
                key={option}
              >
                {option}
              </button>
            );
          })}
    </div>
  );
};

const Cell = ({ text, showContext }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="relative" onClick={() => setShowModal(!showModal)}>
      {showContext ? (
        <ContextModal show={showModal} hide={() => setShowModal(false)} />
      ) : null}
      <p
        className={
          showContext ? "cursor-pointer hover:underline select-none" : ""
        }
      >
        {typeof text === "string" ? text : JSON.stringify(text)}
      </p>
    </div>
  );
};

const CustomTable = ({ headers, data, caption }) => {
  return (
    <TableContainer className="p-8 w-full">
      <Table variant="striped">
        <TableCaption>{caption}</TableCaption>
        <Thead>
          <Tr>
            {headers.map((header, i) => (
              <Th
                key={`header-${header}-${i}`}
                style={{ color: "white", borderBottom: "0px" }}
              >
                {header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row, j) => (
            <Tr key={`row-${j}`}>
              {row.map((cell, i) => (
                <Td
                  key={`cell-${j}-${i}`}
                  style={{ background: "black", color: "white", border: "0px" }}
                >
                  <Cell
                    showContext={
                      false
                      // i === row.length - 1 &&
                      // process.env.NODE_ENV !== "production"
                    }
                    text={cell}
                  />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const Chart = ({ xData, yData, xTitle, yTitle, type }) => {
  const options = {
    grid: { top: 40, right: 60, bottom: 40, left: 60 },
    // title: "This is a title",
    xAxis: {
      type: "category",
      data: xData,
      name: xTitle,
    },
    yAxis: {
      name: yTitle,
      type: "value",
    },
    series: [
      {
        data: yData,
        type: type,
        smooth: true,
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };
  return (
    <div className="p-8 w-full">
      <ReactEcharts option={options}></ReactEcharts>
    </div>
  );
};

function ChatBlock({
  sessionId,
  chat,
  index,
  isShared,
  onLiveboard,
  disableOptions,
  closeLiveboardModal,
  onShareEmbed,
  isVerified,
  onVerified,
}) {
  const isQuestion = chat.isQuestion;
  const [showLiveBoardModal, setShowLiveBoardModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showPromptSavingModal, setShowPromptSavingModal] = useState(false);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [openCharts, setOpenCharts] = useState(false);
  const [selectedX, setSelectedX] = useState(0);
  const [selectedY, setSelectedY] = useState(0);
  const [isVerifiedState, setIsVerified] = useState(isVerified);
  const [selectedTableData, setSelectedTableData] = useState("");
  const [currentValue, setCurrentValue] = useState("");

  const triggerLiveboard = async (index, liveboards) => {
    if (openCharts) {
      setButtonLoading(true);
      await axios
        .post(process.env.REACT_APP_SERVER_URL + "/liveboards/addCharts", {
          xIndex: selectedX,
          yIndex: selectedY,
          dump: selectedTableData,
          liveBoardId: liveboards[0]._id,
        })
        .then(() => {
          toast.success("Chart added to liveboard");
          setSelectedX(0);
          setSelectedY(0);
          setSelectedTableData("");
          setShowLiveBoardModal(false);
          setOpenCharts(false);
        })
        .finally(() => setButtonLoading(false));
      return;
    }
    onLiveboard(index, liveboards);
  };

  const handleVerification = (chat, index) => {
    onVerified(chat, index);
  };

  useEffect(() => {
    if (closeLiveboardModal) {
      setShowLiveBoardModal(false);
    }
  }, [closeLiveboardModal]);

  const onChartClose = () => {
    setOpenCharts(false);
  };

  return (
    <div
      key={`chat-${index}`}
      className={`w-full border-b-[1px] px-6 md:px-12 lg:px-[30px] py-8 whitespace-normal  ${
        isQuestion
          ? "rounded-3xl border border-solid border-[#2563EB] bg-transparent text-white my-8 shadow-[2px_2px_10px_rgb(37,99,235)] whitespace-normal   "
          : "rounded-3xl   bg-[#2563EB] shadow-[2px_2px_10px_rgb(37,99,235)] border border-solid border-[#2563EB] text-white whitespace-normal  "
      }`}
    >
      <CenterPromptSavingModal
        show={showPromptSavingModal}
        currentPrompt={currentValue}
        onClose={() => setShowPromptSavingModal(false)}
      />
      <LiveBoardModal
        show={showLiveBoardModal}
        isLoading={isButtonLoading}
        onSubmit={(liveboards) => {
          triggerLiveboard(index, liveboards);
        }}
        onClose={() => {
          setShowLiveBoardModal(false);
        }}
      />
      <div
        className="flex items-center justify-between"
        style={{ position: "relative" }}
      >
        <p className="whitespace-normal w-full">{chat.text}</p>
        {!isQuestion && isVerifiedState && (
          <CheckCircleIcon className="h-8 w-8" color="#00FF00" />
        )}
        {showOptions ? (
          <div className="bg-black max-w-fit flex px-2 rounded-md min-w-[170px] mx-4  absolute right-[50px]">
            <List size="lg">
              {chat.sqlAnswer && !isShared ? (
                <ListItem my={"1"}>
                  <Button
                    bg="transparent"
                    _hover={{ bg: "#2563EB" }}
                    alignItems="center"
                    minW="100%"
                  >
                    <ExplainBtn
                      sessionId={sessionId}
                      messageId={chat.answerId}
                      sql={chat.sqlAnswer}
                    />
                  </Button>
                </ListItem>
              ) : null}
              {!isQuestion && !disableOptions && (
                <>
                  <ListItem my={"1"}>
                    <Button
                      onClick={() => setShowLiveBoardModal(true)}
                      bg="transparent"
                      _hover={{ bg: "#2563EB" }}
                      minW="100%"
                    >
                      Add to LiveBoard
                    </Button>
                  </ListItem>
                  <ListItem my={"1"}>
                    <Button
                      onClick={() => {
                        setSelectedTableData(JSON.stringify(chat.tableData));
                        setOpenCharts(true);
                      }}
                      bg="transparent"
                      _hover={{ bg: "#2563EB" }}
                      minW="100%"
                    >
                      View as charts
                    </Button>
                  </ListItem>
                  <ListItem my={"1"}>
                    <Button
                      onClick={() => {
                        onShareEmbed(chat, index);
                      }}
                      bg="transparent"
                      _hover={{ bg: "#2563EB" }}
                      minW="100%"
                    >
                      Share answer with query
                    </Button>
                  </ListItem>
                  <ListItem my={"1"}>
                    <Button
                      onClick={() => {
                        handleVerification(chat, index);
                      }}
                      bg="transparent"
                      _hover={{ bg: "#2563EB" }}
                      minW="100%"
                    >
                      Mark as Verified
                    </Button>
                  </ListItem>
                </>
              )}
              <ListItem my={"1"}>
                <Button
                  onClick={() => {
                    setCurrentValue(chat.text);
                    setShowPromptSavingModal(true);
                  }}
                  bg="transparent"
                  _hover={{ bg: "#2563EB" }}
                  minW="100%"
                >
                  Save prompt
                </Button>
              </ListItem>
            </List>
          </div>
        ) : null}
        <Button
          onClick={() => setShowOptions(!showOptions)}
          sx={{
            backgroundColor: isQuestion ? "transparent" : "#2563eb",
            _hover: {
              backgroundColor: "#2563eb",
            },
            _active: {
              backgroundColor: "#2563eb",
            },
          }}
        >
          <img src={options} alt="options" />
        </Button>
        {/* {!isQuestion && process.env.NODE_ENV !== "production" && <AuditIcon />} */}
      </div>

      {chat.tableData ? (
        <React.Fragment>
          <ChartModal
            isModalOpened={openCharts}
            onClose={onChartClose}
            handleAddToLiveBoard={(x, y) => {
              setShowLiveBoardModal(true);
              setSelectedX(x);
              setSelectedY(y);
            }}
            headers={chat.tableData.headers}
            data={chat.tableData.data}
          />
          <CustomTable
            headers={chat.tableData.headers}
            data={chat.tableData.data}
          />
        </React.Fragment>
      ) : null}
      {chat.chartData ? (
        <Chart
          xData={chat.chartData.xData}
          yData={chat.chartData.yData}
          type={"bar"}
          xTitle={chat.chartData.xTitle}
          yTitle={chat.chartData.yTitle}
        />
      ) : null}
      {chat.options ? <Selector options={chat.options} /> : null}
    </div>
  );
}

export default ChatBlock;
