import {
  Box,
  Button,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../common/Loading";
import { useQuery } from "react-query";
import AddProjectModal from "./AddProjectModal";
import axios from "axios";
import RemoveProjectModal from "./RemoveProjectModal";

import "./Projects.css";
import dustbinIcon from "../../assets/dustbin.svg";

function Team() {
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [removeProjectName, setremoveProjectName] = useState("");
  const [removeProjectId, setremoveProjectId] = useState("");
  const [locallyAddedprojects, setlocallyAddedprojects] = useState([]);
  const [locallyRemovedProjects, setlocallyRemovedProjects] = useState([]);

  const { data, isSuccess } = useQuery(
    "getProjects",
    async () => {
      try {
        const result = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/projects/allProjects"
        );
        return result;
      } catch (err) {
        console.log(err);
      }
    },
    {
      staleTime: 180000,
      cacheTime: 180000,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Box>
      <RemoveProjectModal
        show={showRemoveModal}
        projectName={removeProjectName}
        id={removeProjectId}
        onClose={(removedProjectName, removeProjectId) => {
          setShowRemoveModal(false);
          setremoveProjectId("");
          setremoveProjectName("");

          if (removeProjectId && removedProjectName) {
            setlocallyRemovedProjects([
              removeProjectId,
              ...locallyRemovedProjects,
            ]);
          }
        }}
      />

      <div className="flex flex-row justify-between">
        <h1 className="text-3xl text-white mx-auto">Your Projects</h1>
        <Button
          onClick={() => setShowModal(true)}
          sx={{
            backgroundColor: "#2563eb",
            color: "white",
            borderRadius: "md",
            _hover: {
              backgroundColor: "#1849b4",
            },
            _active: {
              backgroundColor: "#1849b4",
            },
            _focus: {
              boxShadow: "none",
            },
          }}
        >
          + Create project
        </Button>
      </div>
      <AddProjectModal
        show={showModal}
        onClose={(newProject) => {
          if (newProject) {
            setlocallyAddedprojects([newProject, ...locallyAddedprojects]);
          }
          setShowModal(false);
        }}
      />
      <Loading isLoading={!data?.data?.projects} />
      <Stack marginTop={"60px"}>
        {data?.data?.projects && (
          <TableContainer>
            <Table variant={"simple"} className="projectsTable text-white ">
              <Thead className="projectsTable--head tableHeading ">
                <Tr className="tableHeading text-blue-500">
                  <Th className="tableHeading ">Project</Th>
                  <Th>Created by</Th>
                  <Th>Schema</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody className="projectsTable--body">
                {[
                  ...locallyAddedprojects.filter(
                    (e) => !locallyRemovedProjects.includes(e._id)
                  ),
                  ,
                  ...data.data.projects.filter(
                    (e) => !locallyRemovedProjects.includes(e._id)
                  ),
                ].map((project, i) => {
                  return (
                    <Tr className="hoverProject" key={`member-row-${i}`}>
                      <Td style={{ padding: "8px" }}>
                        <Link
                          style={{
                            width: "100%",
                            display: "inline-block",
                            padding: "14px",
                          }}
                          to={"/?project=" + project._id}
                        >
                          {project.name}
                        </Link>
                      </Td>

                      <Td>{project["createdBy"]}</Td>
                      <Td>
                        {project.selected_schema?.db_name
                          ? `${project.selected_schema?.db_name} - `
                          : ""}
                        {project.selected_schema?.label}
                      </Td>
                      <Td>
                        <img
                          src={dustbinIcon}
                          alt="Dustbin"
                          className="icon "
                          onClick={() => {
                            setremoveProjectName(project.name);
                            setremoveProjectId(project._id);
                            setShowRemoveModal(true);
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Box>
  );
}

export default Team;
