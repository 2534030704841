import { Select, Input, Button } from "@chakra-ui/react";
import ApexWrapper from "../ApexWrapper/ApexWrapper";
import CohortGraph from "react-cohort-graph";
import CustomCohortGraph from "./CustomCohertChart";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const sampleCohortData = {
  days: {
    "22-05-2016": [200, 10, 20, 30, 40, 10, 20, 20],
    "23-05-2016": [300, 200, 150, 50, 20, 20, 90],
    "24-05-2016": [200, 110, 150, 50, 10, 20],
    "25-05-2016": [100, 10, 10, 50, 20],
  },
  weeks: {
    week1: [200, 10, 20, 30, 40, 10, 20, 20],
    week2: [300, 200, 150, 50, 20, 20, 90],
    week3: [300, 200, 150, 50, 20, 20, 90],
    week4: [300, 200, 150, 50, 20, 20, 90],
  },
  months: {
    jan: [200, 10, 20, 30, 40, 10, 20, 20],
    feb: [300, 200, 150, 50, 20, 20, 90],
    march: [300, 200, 150, 50, 20, 20, 90],
    apr: [300, 200, 150, 50, 20, 20, 90],
  },
};

const DashboardAnalysis = () => {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [selectedChart, setSelectedChart] = React.useState("cohort");
  return (
    <div className="w-full flex text-white">
      <div className="flex flex-1 flex-col">
        <h2 className="text-xl font-semibold mb-3">Dashboard Analysis</h2>
        <div className="w-full h-full overflow-hidden block">
          {selectedChart === "retention" && (
            <ApexWrapper
              chartType="line"
              yAxisData={["7", "6", "8", "5", "5", "2", "1"]}
              y2AxisData={["7", "5", "5", "2", "3", "2", "3"]}
              xAxisData={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"]}
            />
          )}
          {selectedChart === "cohort" && (
            <>
              <CustomCohortGraph
                needPercent={true}
                label={"days"}
                chartData={sampleCohortData}
                secondHeading={"New Users"}
              />
              <CustomCohortGraph
                needPercent={false}
                label={"months"}
                chartData={sampleCohortData}
                secondHeading={"New Users"}
              />
              <CustomCohortGraph
                needPercent={false}
                label={"weeks"}
                chartData={sampleCohortData}
                secondHeading={"New Users"}
              />
            </>
          )}
        </div>
      </div>
      <div className="flex flex-[0.4] flex-col gap-4 p-4 border min-h-screen bg-black rounded-md">
        <p>Select Chart Type :</p>
        <Select onChange={(e) => setSelectedChart(e.target.value)}>
          <option value="cohort">Cohort Analysis</option>
          <option value="retention">Retention Curves</option>
        </Select>
        <p>Select Start Date :</p>
        <DatePicker
          selected={startDate}
          className="w-full text-white bg-transparent border p-1 rounded-md"
          onChange={(e) => setStartDate(e)}
        />
        <p>Select End Date :</p>
        <DatePicker
          selected={endDate}
          className="w-full text-white bg-transparent border p-1 rounded-md"
          onChange={(e) => setEndDate(e)}
        />
        {/* <Input placeholder="End Date" size="md" type="datetime-local" /> */}
        <p>Enter Your Query :</p>
        <Input placeholder="Enter the Query" size="md" type="text" />
        <Button
          sx={{
            backgroundColor: "#2563eb",
            color: "white",
            borderRadius: "md",
            _hover: {
              backgroundColor: "#1849b4",
            },
            _active: {
              backgroundColor: "#1849b4",
            },
            _focus: {
              boxShadow: "none",
            },
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default DashboardAnalysis;
