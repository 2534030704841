import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react";

const CustomCohortGraph = ({
  needPercent,
  label = "days",
  secondHeading,
  chartData,
}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set the data from the sample cohort data
    setData(chartData);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  const generateTooltipContent = (value, total) => {
    const percentage = getPercentage(value, total);
    return `Out of ${total} users ${value} users (${percentage}%) have returned`;
  };
  const getPercentage = (value, total) => {
    return ((value / total) * 100).toFixed(1);
  };
  const getHeatMap = (value, total) => {
    const percentage = getPercentage(value, total);
    if (percentage > 0 && percentage <= 10) {
      return "bg-primary/10";
    }
    if (percentage > 10 && percentage <= 25) {
      return "bg-primary/20";
    }
    if (percentage > 25 && percentage <= 50) {
      return "bg-primary/40";
    }
    if (percentage > 50 && percentage <= 75) {
      return "bg-primary/60";
    }
    if (percentage > 75 && percentage <= 100) {
      return "bg-primary/80";
    }
    return "bg-primary/100";
  };

  return (
    <div className="bg-transparent">
      <table className="border-collapse border border-gray-500 border-opacity-20">
        <thead>
          <tr>
            <th className="border border-gray-500 border-opacity-20"></th>
            {Object.values(data[label])[0].map((day, i) => (
              <th
                // key={day}
                className="text-white border border-gray-500 border-opacity-20 py-2 px-3"
              >
                <span>
                  {i === 0
                    ? secondHeading
                    : `${label[0].toUpperCase()}${label
                        .slice(1)
                        .replace("s", "")} ${i}`}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(data[label]).map((day, index) => (
            <tr key={index}>
              {/* first column */}
              <td className="text-white border border-gray-500 border-opacity-20 py-2 px-3">
                {day}
              </td>
              {/* other columns */}
              {data[label][day].map((d, i) => (
                <td
                  key={d}
                  className={
                    "border border-gray-500 border-opacity-20 py-2 px-3 " +
                    getHeatMap(d, data[label][day][0])
                  }
                >
                  {needPercent
                    ? i !== 0
                      ? `${getPercentage(d, data[label][day][0])} %`
                      : d
                    : d}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomCohortGraph;
