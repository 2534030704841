import axios from "axios";
import React, { useState } from "react";
import { showError } from "../../common/showPopup";
import "./Chat.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { CopyBlock, dracula } from "react-code-blocks";

const ExplainModal = ({ error, query, explanations, show, onClose }) => {
  return (
    <Modal size={"2xl"} isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="black">
        <ModalHeader
          borderRadius="10px 10px 0 0"
          className="bg-black border-t border-l border-r border-[#4A4A4A] text-white "
        >
          Result Explanation
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody
          borderRadius="0px 0px 10px 10px"
          bg="black"
          className=" text-white border-l border-r border-[#4A4A4A] "
          padding={6}
        >
          {error || !query ? (
            "Sorry, an explanation could not be generated for this query"
          ) : (
            <>
              {query ? (
                <div className="mb-8">
                  <p className="font-bold mb-4">Full query:</p>
                  <CopyBlock
                    text={query}
                    language={"SQL"}
                    showLineNumbers={false}
                    theme={dracula}
                    codeBlock
                  />
                  {/* <p className="font-bold mt-10">Breakdown:</p> */}
                </div>
              ) : null}
              {/* {explanations.map((explanation, i) => {
                return (
                  <div key={`explanation-${i}`} className="my-4">
                    <CopyBlock
                      text={
                        explanation?.sql ? explanation.sql : explanation.stage
                      }
                      language={"SQL"}
                      showLineNumbers={false}
                      theme={dracula}
                      codeBlock
                    />
                    <p className="mt-2">{explanation.explanation}</p>
                  </div>
                );
              })} */}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

function ExplainBtn({ sessionId, messageId, sql }) {
  const [loading, setLoading] = useState(false);
  const [explanation, setExplanation] = useState({});
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [query, setQuery] = useState(sql);

  const getExplanation = () => {
    if (!sessionId && !messageId) return;

    setLoading(true);

    axios
      .get(process.env.REACT_APP_SERVER_URL + "/questions/sqlExplanations", {
        params: {
          session_id: sessionId,
          message_id: messageId,
        },
      })
      .then((res) => {
        if (res.data.error) {
          showError("Sorry, an explanation could not be generated");
          setError(true);
        } else {
          setQuery(res.data.query);
          setExplanation(res.data.explanation);
          setShow(true);
        }
      })
      .catch((err) => {
        showError("Sorry, something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <ExplainModal
        error={error}
        query={query}
        explanations={explanation?.explanations}
        show={explanation && show}
        onClose={() => setShow(false)}
      />
      <Button
        isLoading={loading}
        loadingText="Loading..."
        onClick={
          () => setShow(true)
          // explanation?.explanations || error ? setShow(true) : getExplanation()
        }
        sx={{
          width: "100%",
          backgroundColor: "transparent",
          backgroundColor: "#2563eb",
          color: "white",
          borderRadius: "md",
          _hover: {
            backgroundColor: "#1849b4",
          },
          _active: {
            backgroundColor: "#1849b4",
          },
          _focus: {
            boxShadow: "none",
          },
        }}
      >
        Show Query
        {/* {!loading ? "Show Query" : "Please wait..."} */}
      </Button>
    </div>
  );
}

export default ExplainBtn;
