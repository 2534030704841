import React from "react";

function TableContext({ updatedContexts, setUpdatedContexts }) {
  const handleInputChange = (e, dbIndex, schemaIndex, tableIndex) => {
    const newContexts = JSON.parse(JSON.stringify(updatedContexts));
    newContexts[dbIndex].schemas[schemaIndex].tables[
      tableIndex
    ].is_default_context = false;
    newContexts[dbIndex].schemas[schemaIndex].tables[tableIndex].context =
      e.target.value;
    setUpdatedContexts(newContexts);
  };

  return (
    <div>
      <h2 className="text-xl font-bold">Give context to your tables</h2>

      <p className="mt-2">
        Based on your table names, we have selected the most ambiguous tables
        that our model may struggle to understand.
      </p>

      <p className="mt-2">
        Please go through each table and update the context. The more context
        you give, the more accurate we can answer your queries.
      </p>

      <ul className="my-6 h-80 overflow-y-scroll">
        {updatedContexts.map((db, dbIndex) => {
          return (
            <li key={`database-li-${dbIndex}`}>
              <p className="text-xl mb-2 font-bold">{db.name}</p>
              <ul>
                {db.schemas.map((schema, schemaIndex) => {
                  return (
                    <li key={`schema-li-${dbIndex}-${schemaIndex}`}>
                      <p className="text-lg mb-2 font-bold">{schema.name}</p>
                      <ul>
                        {schema.tables.map((table, tableIndex) => {
                          return table.is_unclear ? (
                            <li
                              className="my-2 grid grid-cols-1 sm:grid-cols-3"
                              key={`table-li-${dbIndex}-${schemaIndex}-${tableIndex}`}
                            >
                              <div>{table.name}</div>
                              <input
                                value={table.context}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    dbIndex,
                                    schemaIndex,
                                    tableIndex
                                  )
                                }
                                style={{
                                  border: "1.5px solid #4A4A4A",
                                  background: "transparent",
                                }}
                                maxLength={150}
                                className={"w-full col-span-2 p-1 margin-0"}
                              ></input>
                            </li>
                          ) : null;
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default TableContext;
