import React, { useState, useEffect, useRef } from "react";
import styles from "./suggestions.module.css";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";

const Suggestions = ({
  selectedSchemaName,
  selectedDbId,
  setInput,
  mostRecentQuery,
  shouldHide,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [fetchedQuery, setFetchedQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const listRef = useRef(null);

  const suggestionsPlaceholder = [
    "suggestion 1",
    "suggestion 2",
    "suggestion 3",
    "suggestion 4",
  ];

  useEffect(() => {
    if (shouldHide) {
      setShowSuggestions(false);
    }
  }, [shouldHide]);

  useEffect(() => {
    if (
      mostRecentQuery &&
      showSuggestions &&
      mostRecentQuery !== fetchedQuery
    ) {
      makeReqAndUpdateState();
    }
  }, [showSuggestions]);

  useEffect(() => {
    if (showSuggestions) {
      listRef.current.classList.add(styles.show);
    } else {
      listRef.current.classList.remove(styles.show);
    }
  }, [showSuggestions]);

  const makeReqAndUpdateState = () => {
    setLoading(true);

    const queryContext = mostRecentQuery;
    axios
      .get(
        process.env.REACT_APP_SERVER_URL + "/prompts/contextual-suggestions",
        {
          params: {
            selectedDbId,
            selectedSchemaName,
            queryContext,
          },
        }
      )
      .then((res) => {
        if (res.data?.prompts) {
          setFetchedQuery(queryContext);
          setSuggestions(res.data.prompts);
          setShowSuggestions(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRefresh = () => {
    if (mostRecentQuery) {
      makeReqAndUpdateState();
    }
  };

  const toggleSuggestions = () => {
    setShowSuggestions(!showSuggestions);
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.list}`} ref={listRef}>
        <button
          disabled={loading}
          onClick={onRefresh}
          className={`bg-black disabled:opacity-40 disabled:cursor-not-allowed rounded-md p-1 hover:opacity-70 flex items-center justify-center ${styles.item} ${styles["item-4"]}`}
        >
          <ArrowPathIcon className="w-7 h-7" />
        </button>
        {(suggestions.length
          ? suggestions.slice(0, 4)
          : suggestionsPlaceholder
        ).map((suggestion, index) => (
          <button
            disabled={loading}
            onClick={() => {
              setInput(suggestion);
            }}
            key={index}
            className={`${styles.item} ${
              styles[`item-${index}`]
            } bg-black text-white rounded-md px-4 flex select-none w-[300px] h-[50px] ${
              loading ? "" : "hover:w-[500px]"
            } items-center overflow-hidden ${
              loading && showSuggestions ? "animate-pulse" : ""
            }`}
          >
            {loading ? (
              <div class="h-3 bg-slate-700 w-full rounded-sm"></div>
            ) : (
              <span className="truncate hover:text-clip hover:overflow-x-scroll">
                {suggestion}
              </span>
            )}
          </button>
        ))}
      </div>

      <button
        className="disabled:cursor-not-allowed"
        disabled={!mostRecentQuery}
        onClick={toggleSuggestions}
      >
        {showSuggestions ? (
          <ChevronDownIcon className="w-6 h-6" />
        ) : (
          <ChevronUpIcon className="w-6 h-6" />
        )}
      </button>
    </div>
  );
};

export default Suggestions;
