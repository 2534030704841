import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useClipboard } from "@chakra-ui/react";
import axios from "axios";

function EmbedModal({
  show,
  onClose,
  conversationId,
  data,
  isFullConversation,
}) {
  const { onCopy, value, setValue } = useClipboard("");
  const [hasCopied, setHasCopied] = useState(false);

  const [embedLink, setEmbedLink] = useState(null);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }
  function generateRandomString() {
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result.toLowerCase();
  }
  if (isFullConversation) {
    let stringrand = generateRandomString();
    conversationId =
      conversationId.slice(0, conversationId.length - 4) + stringrand;
  }

  useEffect(() => {
    if (isFullConversation) {
    }
    if (show) {
      axios
        .post(process.env.REACT_APP_SERVER_URL + "/questions/embed", {
          conversationId,
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error);
          } else {
            toast.success("Embed created successfully");
            setEmbedLink(
              window.location.origin + "/conversations#" + conversationId
            );
            const link =
              window.location.origin + "/conversations#" + conversationId;
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Sorry, something went wrong");
        });
    }
  }, [show]);
  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      {embedLink ? (
        <ModalContent bg="black">
          <ModalHeader
            borderRadius="10px 10px 0 0"
            className=" bg-black border-t border-l border-r border-[#4A4A4A] text-white "
          >
            {" "}
            Embed link created, Please copy the link below
          </ModalHeader>
          <ModalCloseButton className="text-white" />
          <ModalBody
            bg="black"
            className=" text-white border-l border-r border-[#4A4A4A] "
          >
            {" "}
            <Flex mb={2}>
              <Input
                value={embedLink}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                mr={2}
              />
            </Flex>
          </ModalBody>
          <ModalFooter
            borderRadius="0px 0px 10px 10px"
            bg="black"
            className=" text-white border-b border-l border-r border-[#4A4A4A] "
          >
            <Button
              onClick={() => {
                copyTextToClipboard(embedLink);
                setHasCopied(true);
              }}
              sx={{
                backgroundColor: "#2563eb",
                color: "white",
                borderRadius: "md",
                _hover: {
                  backgroundColor: "#1849b4",
                },
                _active: {
                  backgroundColor: "#1849b4",
                },
                _focus: {
                  boxShadow: "none",
                },
              }}
            >
              {hasCopied ? "Copied!" : "Copy"}
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <p>Please wait...</p>
      )}
    </Modal>
  );
}

export default EmbedModal;
