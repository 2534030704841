import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Flex,
  ListItem,
  Checkbox,
  List,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import addIcon from "../../assets/addIcon.svg";
import checkIcon from "../../assets/checkIcon.svg";
import axios from "axios";
import "./Chat.css";

function LiveBoardModal({ show, onClose, onSubmit, isLoading }) {
  useEffect(() => {
    if (show) {
      console.log("Shown");
      (async () => {
        try {
          const result = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/liveboards/allLiveBoards"
          );
          const liveBoards = result?.data?.liveboard;
          setValues(liveBoards);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [show]);

  const [values, setValues] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [addValue, setAddValue] = useState(false);

  const [inputValue, setInputValue] = React.useState("");

  const handleSelectChange = (changedIndex) => {
    setValues(
      values.map((value, index) =>
        index === changedIndex
          ? { ...value, isChecked: !value.isChecked }
          : value
      )
    );
  };

  const handleChange = (event) => setInputValue(event.target.value);

  const createLiveBoard = async (liveName) => {
    return await axios.post(
      process.env.REACT_APP_SERVER_URL + "/liveboards/create",
      {
        name: liveName,
      }
    );
  };

  async function handleChoice() {
    const result = await createLiveBoard(inputValue);
    setValues([...values, { ...result.data.liveBoardDoc, isChecked: true }]);
    setInputValue("");
    setAddValue(false);
  }

  function submitOn() {
    const checked = values.filter((v) => v.isChecked);
    console.log("Submitted", checked);
    onSubmit(checked);
  }

  return (
    <Modal isOpen={show} onClose={onClose} width="lg">
      <ModalOverlay />
      <ModalContent bg="black">
        <ModalHeader
          borderRadius="10px 10px 0 0"
          className=" bg-black border-t border-l border-r border-[#4A4A4A] text-white "
        >
          {" "}
          Live Board
        </ModalHeader>
        <ModalCloseButton className="text-white" />

        <ModalBody
          bg="black"
          className=" text-white border-l border-r border-[#4A4A4A] "
        >
          <br></br>
          {!values.length ? "Please wait..." : null}
          <List>
            {values.map((value, index) => (
              <ListItem key={index}>
                <Checkbox
                  my={"1.5"}
                  size="lg"
                  onChange={() => handleSelectChange(index)}
                  isChecked={value.isChecked || false}
                >
                  {value.name}
                </Checkbox>
              </ListItem>
            ))}
          </List>

          {!addValue ? (
            <Flex direction="column">
              <Button
                isLoading={isLoading || false}
                onClick={() => setAddValue(true)}
                margin="1rem 0"
                sx={{
                  backgroundColor: "transparent",
                  border: "1px solid #4a4a4a",

                  color: "white",
                  borderRadius: "md",
                  _hover: {
                    backgroundColor: "transparent",
                  },
                  _active: {
                    backgroundColor: "#2563eb",
                  },
                }}
              >
                <img src={addIcon} alt="add icon" />
                Create a new LiveBoard
              </Button>
              <Button
                isLoading={isLoading || false}
                onClick={submitOn}
                sx={{
                  backgroundColor: "#2563eb",
                  color: "white",
                  borderRadius: "md",
                  _hover: {
                    backgroundColor: "#1849b4",
                  },
                  _active: {
                    backgroundColor: "#1849b4",
                  },
                }}
              >
                Submit
              </Button>
            </Flex>
          ) : (
            <Flex margin="1rem 0">
              <Input value={inputValue} onChange={handleChange} mx={"1.5"} />
              <Button
                onClick={handleChoice}
                colorScheme="green.500"
                bg="green.500"
              >
                <img src={checkIcon} alt="check icon" />
              </Button>
            </Flex>
          )}
        </ModalBody>
        <ModalFooter
          borderRadius="0px 0px 10px 10px"
          bg="black"
          className=" text-white border-b border-l border-r border-[#4A4A4A] "
        ></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default LiveBoardModal;
