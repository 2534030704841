import {
  CheckCircleIcon,
  CheckIcon,
  ListBulletIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import ReactTimeAgo from "react-time-ago";

const AuditModal = ({ show, onClose }) => {
  const [people, setPeople] = useState([]);

  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Data Audit</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack spacing={4} marginY={"20px"}>
            {people.length ? (
              <ul>
                {people.map((person) => {
                  return (
                    <div>
                      <p className="mb-4">Data verified by:</p>
                      <li className="flex items-center gap-5" key={person}>
                        <div className="w-10 h-10 text-white bg-blue-500 flex items-center justify-center rounded-full p-1">
                          {person.charAt(0)}
                        </div>

                        <div>
                          <p className="font-semibold text-lg">{person}</p>
                          <ReactTimeAgo
                            className="text-sm"
                            date={new Date()}
                            locale="en-US"
                          />
                        </div>
                      </li>
                    </div>
                  );
                })}
              </ul>
            ) : (
              <div className="text-center">This data has not be verified</div>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            // isLoading={loading}
            loadingText="Adding.."
            bg={people.length ? "red.400" : "blue.400"}
            color={"white"}
            _hover={{
              bg: "blue.500",
            }}
            onClick={() => setPeople(["Alex"])}
            leftIcon={
              people.length ? (
                <XMarkIcon className="w-5 h-5" />
              ) : (
                <CheckIcon className="w-5 h-5" />
              )
            }
          >
            {people.length ? "Unverify data" : "Verify data"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

function AuditIcon() {
  const [verified, setVerified] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <AuditModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setVerified(true);
        }}
      />
      <Tooltip id="status-tooltip" />
      {verified ? (
        <CheckCircleIcon
          data-tooltip-id="status-tooltip"
          data-tooltip-content="This data has been verified"
          className="w-6 h-6 text-green-500 cursor-pointer"
          onClick={() => setShowModal(true)}
        />
      ) : (
        <ListBulletIcon
          data-tooltip-id="status-tooltip"
          data-tooltip-content="This data is unverified"
          className="w-6 h-6 cursor-pointer"
          onClick={() => setShowModal(true)}
        />
      )}
    </div>
  );
}

export default AuditIcon;
