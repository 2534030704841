import { toast } from "react-toastify";

export const showError = (message) => {
  toast.error(message, {
    toastId: "toast-error",
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 7500,
  });
};

export const showSuccess = (message) => {
  showToast(message, "success");
};

export const showInfo = (message) => {
  showToast(message, "info");
};

const showToast = (message, type) => {
  toast[type](message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 7500,
  });
};
