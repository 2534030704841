import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useState } from "react";
import ChatBlock from "../chat/ChatBlock";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./style.css";

import { toast } from "react-toastify";

function Converations() {
  const [locationHash, setLocationHash] = useState(null);
  const [chats, setChats] = useState([]);
  const [metaData, setMetaData] = useState({});
  let location = useLocation();

  useEffect(() => {
    let conversationId = location.hash.slice(1);
    setLocationHash(conversationId);
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/questions/getEmbed", {
        conversationId,
      })
      .then((res) => {
        setMetaData(res.data);
        const temp1 = res.data.data;
        console.log(res.data.data);
        let resources = JSON.parse(temp1);
        console.log(resources);
        if (typeof resources === "string") {
          resources = JSON.parse(resources);
        }
        if (resources.length) {
          setChats(resources);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Sorry, something went wrong");
      });
  }, [location]);

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      direction={"column"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <div className="text-2xl sm:text-3xl font-bold pt-5 mb-16 text-center">
        {chats.length
          ? `Layerup Chat - created by ${metaData["createdBy"]}`
          : `Please wait....`}
      </div>
      <br></br>
      {chats.map((chat, i) => {
        return chat.ready ? (
          <ChatBlock
            key={`chat-block-${i}`}
            chat={chat}
            index={i}
            isShared={true}
          />
        ) : null;
      })}
    </Flex>
  );
}

export default Converations;
