import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import axios from "axios";
import { showError } from "../../common/showPopup";
import { useQuery } from "react-query";
import Select from "react-select";

const customStyles = {
  container: (base) => ({
    ...base,
    color: "white",
  }),
  menu: (base) => ({
    ...base,
    color: "white",
    background: "black",
    border: "1px solid #4a4a4a",
  }),
  option: (base) => ({
    ...base,
    color: "white",
    background: "black",
    "&select": { color: "white" },

    "&:hover": { background: "#2563eb" },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#ffffff",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
};

function AddPhraseModal({
  show,
  onClose,
  isEdit,
  id,
  existingPhrase,
  existingDefintion,
  existingSelectedEntities,
}) {
  const [phrase, setPhrase] = useState(existingPhrase || "");
  const [definition, setDefinition] = useState(existingDefintion || "");

  const [loading, setLoading] = useState(false);
  const [phraseInvalid, setPhraseInvalid] = useState(false);
  const [definitionInvalid, setDefinitionInvalid] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState([]);

  useEffect(() => {
    if (!isEdit) {
      setPhrase("");
      setDefinition("");
      setSelectedEntities([]);
    } else {
      setPhrase(existingPhrase);
      setDefinition(existingDefintion);
      setSelectedEntities(existingSelectedEntities);
    }
  }, [show]);

  const {
    data: databasesRes,
    isLoading,
    refetch,
  } = useQuery(
    "getDatabases",
    async () => {
      try {
        return await axios.get(
          process.env.REACT_APP_SERVER_URL + "/integrations/databases"
        );
      } catch (err) {
        console.log(err);
      }
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  const databaseOptions = databasesRes?.data
    ? databasesRes.data.databases.map((database) => {
        return {
          label: database.name,
          options: database.schemas.map((schema) => {
            return {
              label: schema.name,
              value: { schema_name: schema.name, database_id: database._id },
            };
          }),
        };
      })
    : [];

  const onAdd = () => {
    let error = false;

    if (!phrase) {
      error = true;
      setPhraseInvalid(true);
    }

    if (!definition) {
      error = true;
      setDefinitionInvalid(true);
    }

    if (error) return;

    setLoading(true);

    if (!selectedEntities || !selectedEntities.length) {
      showError("Please select a schema");
      return;
    }

    const related_entities = selectedEntities.map((e) => e.value);

    let endpoint = "/dictionary/create-definition";
    let method = "post";
    let params = {
      phrase,
      definition,
      locked: false,
      related_entities,
    };

    if (isEdit) {
      method = "patch";
      endpoint = "/dictionary/edit-definition";
      params = {
        id,
        phrase,
        definition,
        related_entities,
      };
    }

    axios[method](process.env.REACT_APP_SERVER_URL + endpoint, params)
      .then((res) => {
        if (isEdit) {
          onClose(res.data, true);
        } else {
          onClose(res.data);
        }
      })
      .catch((err) => {
        showError("Sorry, something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="black">
        <ModalHeader
          borderRadius="10px 10px 0 0"
          className="bg-black border-t border-l border-r border-[#4A4A4A] text-white"
        >
          {isEdit ? "Edit" : "Add"} Phrase
        </ModalHeader>
        <ModalCloseButton className="text-white" />

        <ModalBody className="bg-black  border-l border-r border-[#4A4A4A] text-white">
          <Stack spacing={4} marginY={"20px"}>
            <FormControl isInvalid={phraseInvalid} id="phrase" isRequired>
              <FormLabel display={"inline-block"}>Phrase</FormLabel>
              <Input
                borderColor="#4A4A4A"
                value={phrase}
                onChange={(e) => setPhrase(e.target.value)}
                type="text"
              />
              <FormErrorMessage>Phrase is required</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={definitionInvalid}
              id="definition"
              isRequired
            >
              <FormLabel>Definition</FormLabel>
              <Textarea
                maxLength={180}
                borderColor="#4A4A4A"
                value={definition}
                onChange={(e) => setDefinition(e.target.value)}
                type="text"
              />
              <FormErrorMessage>Definition is required</FormErrorMessage>
            </FormControl>

            <Select
              menuPosition="fixed"
              className="dropdown pt-4"
              classNamePrefix="select"
              menuPlacement="bottom"
              placeholder="Schema definition applies to"
              isLoading={isLoading}
              isClearable={true}
              isDisabled={isLoading}
              isMulti={true}
              options={databaseOptions}
              value={selectedEntities}
              styles={customStyles}
              onChange={(e) => {
                setSelectedEntities(e);
              }}
            />
          </Stack>
        </ModalBody>

        <ModalFooter
          borderRadius="0px 0px 10px 10px"
          className="bg-black border-b border-l border-r border-[#4A4A4A] text-white"
        >
          <Button
            isLoading={loading}
            loadingText="Saving..."
            onClick={onAdd}
            sx={{
              backgroundColor: "#2563eb",
              color: "white",
              borderRadius: "md",
              _hover: {
                backgroundColor: "#1849b4",
              },
              _active: {
                backgroundColor: "#1849b4",
              },
              _focus: {
                boxShadow: "none",
              },
            }}
          >
            {isEdit ? "Save" : "Add"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddPhraseModal;
