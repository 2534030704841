import React from "react";
import { useState } from "react";
import EmptyState from "./EmptyState";
import ChatInput from "./ChatInput";
import ChatBlock from "./ChatBlock";
import { useEffect } from "react";
import UploadModal from "./UploadModal";
import DeleteModal from "./DeleteModal";
import axios from "axios";
import Loading from "../common/Loading";
import { useRef } from "react";
import { ShareIcon } from "@heroicons/react/24/outline";
import {
  TrashIcon,
  XMarkIcon,
  BookmarkIcon,
} from "@heroicons/react/24/outline";
import Confetti from "react-confetti";

import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import EmbedModal from "./EmbedModal";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from "@chakra-ui/react";

import "./Chat.css";
import { showError, showSuccess } from "../../common/showPopup";
import SchemaHint from "./schemaHint/SchemaHint";
import DetailedPromptSavingModal from "../PromptSavingModals/DetailedPromptSavingModal";
import Suggestions from "./suggestions/Suggestions";

function ChatBase() {
  const [chats, setChats] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [embedVals, setEmbedVals] = useState([]);
  const [liveBoardCloseModal, setLiveBoardCloseModal] = useState(false);
  const [sessionId, setSessionId] = useState();
  const [selectedSchema, setSelectedSchema] = useState();
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [savedSessionId, setSavedSessionId] = useState(false);
  const [isBookmarkPromptModalOpen, setBookmarkPromptModalOpen] =
    useState(false);
  const [deleteValue, setdeleteValue] = useState(0);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [clearingChat, setClearingChat] = useState(false);
  const [showSchemaHint, setShowSchemaHint] = useState(false);
  const [mostRecentQuery, setMostRecentQuery] = useState("");
  const [chatInputLoading, setChatInputLoading] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

  let location = useLocation();
  let navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const scrollableDivRef = useRef(null);

  const clearChat = () => {
    if (clearingChat || !sessionId) return;

    setClearingChat(true);

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/questions/clearConversation", {
        conversationId: sessionId,
      })
      .then(() => {
        setChats([]);
      })
      .catch(() => {
        showError("Sorry, your conversation could not be cleared");
      })
      .finally(() => {
        setClearingChat(false);
      });
  };

  useEffect(() => {
    getAllSavedPrompts();
  }, []);

  useEffect(() => {
    if (params.has("project")) {
      setProjectId(params.get("project"));
    }
    if (params.has("success")) {
      setIsPaymentSuccess(true);
    }
  }, [params]);

  useEffect(() => {
    if (sessionId && projectId && !savedSessionId) {
      axios
        .post(process.env.REACT_APP_SERVER_URL + "/projects/setConversation", {
          conversationId: sessionId,
          projectId: projectId,
        })
        .then(() => {
          setSavedSessionId(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [sessionId]);

  const getProjectData = () => {
    if (projectId) {
      setLoading(true);
      axios
        .get(process.env.REACT_APP_SERVER_URL + "/projects/project", {
          params: {
            projectId: projectId,
          },
        })
        .then((res) => {
          const projectRes = res.data?.project;
          const conversationRes = res.data?.conversation;

          if (projectRes) {
            setSessionId(projectRes.conversation_id);

            if (projectRes.conversation_id) {
              setSavedSessionId(true);
            }

            if (projectRes.selected_schema) {
              setSelectedSchema({
                label: projectRes.selected_schema.label,
                value: projectRes.selected_schema.value,
              });
            }
          }

          if (conversationRes) {
            if (conversationRes.client_messages) {
              let lastUserMessage;

              setChats(
                conversationRes.client_messages.map((message) => {
                  if (message.isUser) {
                    lastUserMessage = message.questionText;
                    return {
                      // FIX
                      // id: res.data.id,
                      text: message.questionText,
                      isQuestion: true,
                      ready: true,
                    };
                  } else {
                    return {
                      // FIX
                      // id: res.data.id,
                      text: message.answerText,
                      isQuestion: false,
                      ready: true,
                      verified: message.verified,
                      tableData: message.tableData,
                      // FIX
                      // answerId: res.data?.answer?.sqlAnswerId,
                      sqlAnswer: message.sqlAnswer,
                    };
                  }
                })
              );
              setMostRecentQuery(lastUserMessage);
            }
          }
        })
        .catch((err) => {
          showError("Please select a project");
          navigate("/projects");
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getProjectData();
  }, [projectId]);

  useEffect(() => {
    scrollableDivRef.current.scrollTo({
      top: scrollableDivRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [chats]);

  const { data, isLoading } = useQuery(
    "getPromptSuggestions" +
      selectedSchema?.value?.database_id +
      selectedSchema?.value?.schema_name,
    async () => {
      if (
        selectedSchema?.value?.database_id &&
        selectedSchema?.value?.schema_name
      ) {
        try {
          return await axios.get(
            process.env.REACT_APP_SERVER_URL + "/prompts/suggestions",
            {
              params: {
                selectedDbId: selectedSchema?.value?.database_id,
                selectedSchemaName: selectedSchema?.value?.schema_name,
              },
            }
          );
        } catch (err) {
          console.log(err);
        }
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  if (!params.has("project") && !isPaymentSuccess) {
    navigate("/projects");
  }

  const handleDeleteSchema = (ev, value) => {
    ev.stopPropagation();
    setdeleteValue(value);
    setShowDeleteModal(true);
  };

  const prompts = data?.data?.suggestions
    ? [
        data.data.suggestions.slice(0, 2),
        data.data.suggestions.slice(2, 5),
        data.data.suggestions.slice(5, 7),
      ]
    : [];

  const onEmbed = (chat, index) => {
    const dump = JSON.stringify(chats.slice(index - 1, index + 1));
    setEmbedVals(dump);
    setShowModal(true);
  };

  const onVerified = async (chat, index) => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/questions/verifyIndex",
        {
          conversation_id: sessionId,
          index: index,
        }
      );
      toast.success("Marked as verfied!");
      getProjectData();
    } catch (err) {
      console.log(err);
      toast.error("Marking this as verified failed!");
    }
  };

  const [isPromptsLoading, setIsPromptsLoading] = useState(false);

  const [promptsCompletion, setPrompts] = useState([]);

  const getAllSavedPrompts = async () => {
    setIsPromptsLoading(true);
    try {
      let out = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/questions/allSavedPrompts"
      );
      setPrompts(out.data.prompts || []);
    } catch (error) {
      console.log("error in getting prompts", error);
    } finally {
      setIsPromptsLoading(false);
    }
  };

  const onLiveboard = async (index, liveboards) => {
    const question = chats[index - 1];
    const questionText = question.text;
    const questionIndex = index - 1;
    const selected_schema = selectedSchema?.value?.schema_name;
    const selected_db = selectedSchema?.value?.database_id;

    if (sessionId) {
      const dump = JSON.stringify(chats.slice(0, index + 1));
      const result = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/liveboards/saveLiveBoard",
        {
          questionIndex,
          questionText,
          selected_schema,
          selected_db,
          dump,
          sessionId,
          liveBoards: liveboards,
        }
      );
      setLiveBoardCloseModal(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      toast.success("Liveboard created successfully");
    }
  };

  if (isPaymentSuccess) {
    return (
      <div>
        <Confetti />
        <Modal isCentered isOpen={true}>
          <ModalContent bg="black">
            <ModalHeader
              borderRadius="10px 10px 0 0"
              className="bg-black border-t border-l border-r border-[#4A4A4A] text-white"
            >
              Account Upgraded!
            </ModalHeader>
            <ModalFooter
              borderRadius="0px 0px 10px 10px"
              className="bg-black border-b border-l border-r border-[#4A4A4A] text-white"
            >
              <Button
                onClick={() => navigate("/projects")}
                sx={{
                  backgroundColor: "#2563eb",
                  color: "white",
                  borderRadius: "md",
                  _hover: {
                    backgroundColor: "#1849b4",
                  },
                  _active: {
                    backgroundColor: "#1849b4",
                  },
                  _focus: {
                    boxShadow: "none",
                  },
                }}
              >
                Done
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    );
  }

  return (
    <div className="h-[90vh] w-full flex flex-col items-center justify-between">
      <UploadModal
        show={showUploadModal}
        onClose={() => setShowUploadModal(false)}
      />
      <DetailedPromptSavingModal
        isOpen={isBookmarkPromptModalOpen}
        onSelect={(val) => {
          setBookmarkPromptModalOpen(false);
          getAllSavedPrompts();
          setInputVal(val);
        }}
        onClose={() => setBookmarkPromptModalOpen(false)}
      />
      <DeleteModal
        show={showDeleteModal}
        value={deleteValue}
        onClose={() => setShowDeleteModal(false)}
      />
      <div
        className="w-full overflow-auto max-h-[80vh] scrollBarChat"
        ref={scrollableDivRef}
      >
        {!loading && !isLoading && sessionId ? (
          <EmbedModal
            show={showModal}
            data={embedVals}
            isFullConversation={embedVals.length > 1 ? true : false}
            conversationId={sessionId}
            onClose={() => {
              setEmbedVals([]);
              setShowModal(false);
            }}
          />
        ) : null}
        {!loading && !isLoading && (
          <EmptyState
            selectedSchemaName={selectedSchema?.value?.schema_name}
            selectedDbId={selectedSchema?.value?.database_id}
            setInput={setInputVal}
            prompts={prompts}
          />
        )}
        <Loading isLoading={loading || isLoading} />

        {loading || isLoading || !chats
          ? null
          : chats.map((chat, i) => {
              return chat.ready ? (
                <ChatBlock
                  key={`chat-block-${i}`}
                  chat={chat}
                  index={i}
                  onShareEmbed={onEmbed}
                  isVerified={chat.verified}
                  onVerified={onVerified}
                  closeLiveboardModal={liveBoardCloseModal}
                  onLiveboard={onLiveboard}
                  sessionId={sessionId}
                />
              ) : null;
            })}
      </div>

      <div className="w-full relative flex justify-evenly">
        <SchemaHint
          show={showSchemaHint}
          setShow={setShowSchemaHint}
          schema={selectedSchema}
        />
        <ChatInput
          className="glass"
          selectedSchema={selectedSchema}
          sessionId={sessionId}
          options={promptsCompletion || []}
          setSessionId={setSessionId}
          setMostRecentQuery={setMostRecentQuery}
          inputVal={inputVal}
          setChatInputLoading={setChatInputLoading}
          setInputVal={setInputVal}
          addChat={(newChats) => {
            setShowSchemaHint(false);
            setChats([...chats, ...newChats]);
            setChatInputLoading(false);
          }}
        />

        <div
          style={{ display: "flex" }}
          className="flex items-center justify-between gap-5"
        >
          <Tooltip
            hasArrow
            label="Save Prompt"
            bg={"transparent"}
            color="white"
          >
            <div onClick={() => setBookmarkPromptModalOpen(true)}>
              <BookmarkIcon
                style={{ color: "white", cursor: "pointer" }}
                className="w-6 h-8 ml-1"
              />
            </div>
          </Tooltip>
          <Tooltip hasArrow label="Upload" bg="transparent" color="white">
            <ArrowUpOnSquareIcon
              onClick={() => setShowUploadModal(true)}
              style={{ color: "white", cursor: "pointer" }}
              className="w-6 h-8"
            />
          </Tooltip>

          {sessionId ? (
            <Tooltip hasArrow label="Share" bg="transparent" color="white">
              <ShareIcon
                style={{ color: "white", cursor: "pointer" }}
                className="w-6 h-8"
                onClick={() => {
                  setEmbedVals(chats);
                  setShowModal(true);
                }}
              />
            </Tooltip>
          ) : null}

          {chats && chats.length ? (
            <Tooltip
              hasArrow
              label="Clear chats"
              bg="transparent"
              color="white"
            >
              <TrashIcon
                style={{ color: "white", cursor: "pointer" }}
                className="w-6 h-8"
                onClick={() => {
                  clearChat();
                }}
              />
            </Tooltip>
          ) : null}

          {chats && chats.length ? (
            // <Tooltip
            //   hasArrow
            //   label="View suggestions"
            //   bg="transparent"
            //   color="white"
            // >
            //   <div>
            <Suggestions
              shouldHide={showSchemaHint || chatInputLoading}
              mostRecentQuery={mostRecentQuery}
              selectedSchemaName={selectedSchema?.value?.schema_name}
              selectedDbId={selectedSchema?.value?.database_id}
              setInput={setInputVal}
            />
          ) : //   </div>
          // </Tooltip>
          null}
        </div>
      </div>
    </div>
  );
}

export default ChatBase;
