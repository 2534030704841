import React from "react";
import { useQuery } from "react-query";
import axios from "axios";
import Loading from "../common/Loading";
import "./Chat.css";

function EmptyState({ selectedDbId, selectedSchemaName, setInput, prompts }) {
  const onPromptClick = (prompt) => {
    setInput(prompt);
  };

  return (
    <div className="px-4 md:px-6 lg:px-24 py-6 flex flex-col items-center text-center text-white">
      <h1 className="text-3xl font-semibold my-3 ">Layerup chat</h1>
      <h2 className="text-xl mb-6">Example prompts (click to use)</h2>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
        {prompts &&
          prompts.map((promptCol, i) => {
            return (
              <div
                key={`prompt-col-${i}`}
                className="grid grid-cols-1 w-full gap-4 flex-1"
              >
                {promptCol.map((prompt) => {
                  return (
                    <div
                      onClick={() => onPromptClick(prompt)}
                      key={prompt}
                      className="m-1 cursor-pointer delay-100 select-none active:scale-95 hover:scale-105 transition-transform ease-in-out bg-transparent p-4 rounded-md flex-1 flex items-center justify-center blackDiv text-white shadow-[1px_1px_2px_2px_rgb(37,99,235)]"
                    >
                      {prompt}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default EmptyState;
