import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordConfirmInvalid, setPasswordConfirmInvalid] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const password = useRef();
  const passwordConfirm = useRef();

  const onRegister = () => {
    let error = false;

    if (!password.current.value) {
      setPasswordInvalid(true);
      error = true;
    }

    if (!passwordConfirm.current.value) {
      setPasswordConfirmInvalid(true);
      error = true;
    }

    if (password.current.value.length < 6) {
      toast.error("Your password must be at least 6 characters long");
      error = true;
    } else if (password.current.value !== passwordConfirm.current.value) {
      toast.error("Passwords must match");
      error = true;
    }

    if (error) {
      return;
    }

    setLoading(true);

    const params = {
      password: password.current.value,
    };

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/auth/change-password", params)
      .then((res) => {
        navigate("/");

        setLoading(false);
      })
      .catch((err) => {
        toast.error("Sorry, something went wrong");
        setLoading(false);
      });
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} w={"420px"} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} textAlign={"center"}>
            Change password
          </Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl isInvalid={passwordInvalid} id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  ref={password}
                  type={showPassword ? "text" : "password"}
                />
                <InputRightElement h={"full"}>
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>Your password is required</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={passwordConfirmInvalid}
              id="password-confirm"
              isRequired
            >
              <FormLabel>Confirm Password</FormLabel>
              <InputGroup>
                <Input
                  ref={passwordConfirm}
                  type={showConfirmPassword ? "text" : "password"}
                />
                <InputRightElement h={"full"}>
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      setShowConfirmPassword(
                        (showConfirmPassword) => !showConfirmPassword
                      )
                    }
                  >
                    {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                Your confirm password is required
              </FormErrorMessage>
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button
                isLoading={loading}
                loadingText="Submitting"
                size="lg"
                bg={"blue.400"}
                color={"white"}
                onClick={onRegister}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Set password
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default ChangePassword;
