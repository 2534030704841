import React from "react";
import { Box, Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import {
  PencilIcon,
  TrashIcon,
  LockClosedIcon,
  LockOpenIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import AddPhraseModal from "./AddPhraseModal";
import { useEffect } from "react";
import axios from "axios";
import { showError } from "../../common/showPopup";
import Loading from "../common/Loading";

function DictionaryRow({ row, i, setEdit }) {
  const [deleted, setDeleted] = useState(false);
  const [locked, setLocked] = useState(row.locked);

  const onDelete = () => {
    setDeleted(true);
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/dictionary/delete-definition",
        {
          id: row._id,
        }
      )
      .catch((err) => {
        showError("Sorry, your definition could not be deleted");
      });
  };

  const onLockClicked = () => {
    const newLocked = !locked;
    setLocked(newLocked);
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/dictionary/lock-definition", {
        id: row._id,
        locked: newLocked,
      })
      .catch((err) => {
        showError("Sorry, your definition could not be deleted");
      });
  };

  if (deleted) {
    return null;
  }

  return (
    <Tr key={`${i}-${row.phrase}`}>
      <Td className="w-1/6">{row.phrase}</Td>
      <Td className="w-4/6">{row.definition}</Td>
      <Td className="w-1/6">
        <div className="flex items-center justify-between">
          <button
            disabled={locked}
            onClick={() => setEdit(row)}
            className="p-2 bg-gray-500 disabled:cursor-not-allowed rounded-md cursor-pointer"
          >
            <PencilIcon className="w-4 h-4" />
          </button>
          <button
            onClick={onLockClicked}
            className={`p-2 ${
              locked ? "bg-green-500" : "bg-gray-500"
            } rounded-md cursor-pointer`}
          >
            {locked ? (
              <LockClosedIcon className="w-4 h-4" />
            ) : (
              <LockOpenIcon className="w-4 h-4" />
            )}
          </button>
          <button
            onClick={onDelete}
            className="p-2 bg-red-500 rounded-md cursor-pointer"
          >
            <TrashIcon className="w-4 h-4" />
          </button>
        </div>
      </Td>
    </Tr>
  );
}

function DictionaryTable({ definitions, setEdit }) {
  return (
    <div className="w-full overflow-x-scroll">
      <Table className="text-white min-w-[600px]" variant={"simple"}>
        <Thead>
          <Tr>
            <Th style={{ color: "#2563eb" }}>Phrase</Th>
            <Th style={{ color: "#2563eb" }}>Definition</Th>
            <Th></Th>
          </Tr>
        </Thead>

        <Tbody>
          {definitions.map((row, i) => {
            return (
              <DictionaryRow
                setEdit={setEdit}
                key={`dictionary-row-${i}`}
                row={row}
                i={i}
              />
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
}

function Dictionary() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [definitions, setDefinitions] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [existingDefinition, setExistingDefinition] = useState({});

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/dictionary/all-definitions")
      .then((res) => {
        if (res.data?.definitions) {
          setDefinitions(res.data.definitions);
        }
      })
      .catch(() => {
        showError("Sorry, something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Box>
      <div className="flex flex-col justify-between mb-12">
        <h1 className="mx-auto text-3xl text-center text-white">Dictionary</h1>

        <h2 className="mx-auto text-xl my-2 text-center text-white">
          Use this to store all your business and data terminology.
        </h2>
        <h3 className="mx-auto text-lg text-center text-white">
          Queries will automatically be translated based on these definitions.
        </h3>
      </div>

      <div className="my-4 mb-8 float-right">
        <Button
          onClick={() => {
            setIsEdit(false);
            setShowAddModal(true);
          }}
          disabled={loading}
          sx={{
            backgroundColor: "#2563eb",
            color: "white",
            borderRadius: "md",
            _hover: {
              backgroundColor: "#1849b4",
            },
            _active: {
              backgroundColor: "#1849b4",
            },
            _focus: {
              boxShadow: "none",
            },
          }}
        >
          + Add phrase
        </Button>
      </div>

      <AddPhraseModal
        isEdit={isEdit}
        show={showAddModal}
        id={existingDefinition?._id}
        existingDefintion={existingDefinition?.definition}
        existingPhrase={existingDefinition?.phrase}
        existingSelectedEntities={
          existingDefinition?.related_entities
            ? existingDefinition.related_entities.map((entity) => {
                return {
                  label: entity?.schema_name,
                  value: {
                    schema_name: entity?.schema_name,
                    database_id: entity?.database_id,
                  },
                };
              })
            : []
        }
        onClose={(addedPhrase, edit) => {
          if (addedPhrase) {
            if (edit) {
              const oldDefinitions = [...definitions];
              const oldIndex = oldDefinitions
                .map((e) => e._id)
                .indexOf(addedPhrase._id);

              if (oldIndex !== -1) {
                oldDefinitions[oldIndex] = addedPhrase;
              }
              setDefinitions(oldDefinitions);
            } else {
              setDefinitions([...definitions, addedPhrase]);
            }
          }

          setShowAddModal(false);
        }}
      />

      <Loading isLoading={loading} />
      {!loading && definitions && definitions.length && (
        <DictionaryTable
          setEdit={(definition) => {
            console.log(definition);
            setExistingDefinition(definition);
            setIsEdit(true);
            setShowAddModal(true);
          }}
          definitions={definitions}
        />
      )}
    </Box>
  );
}

export default Dictionary;
