import { useState } from "react";
import { Transition } from "@headlessui/react";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline";
import { filter } from "@chakra-ui/react";

const Typeahead = ({ options, onSelect, placeholder, onClick }) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const filteredOptions =
    options.filter(
      (option) => option.prompt.toLowerCase().indexOf(query.toLowerCase()) > -1
    ) || [];
  const filteredOptionsBasedAlias =
    options.filter(
      (option) => option.alias.toLowerCase().indexOf(query.toLowerCase()) > -1
    ) || [];

  return (
    <div className="relative w-full" onClick={onClick}>
      <div className="flex w-full">
        <div className="flex-1 w-full">
          <div className="relative rounded-md w-full shadow-sm">
            <div className="absolute inset-y-0 left-0 w-full pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassCircleIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="search"
              id="search"
              className="block w-full pl-10 bg-transparent text-white sm:text-sm py-4 border-gray-300 rounded-md"
              placeholder={placeholder || "Search"}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                setIsOpen(true);
              }}
            />
          </div>
        </div>
      </div>
      <Transition
        show={isOpen && query.length > 0}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute z-50 mt-1 w-full rounded-md bg-black text-white shadow border">
          <ul className="max-h-60 overflow-auto divide-y">
            {(filteredOptionsBasedAlias.length === 0
              ? filteredOptions
              : filteredOptionsBasedAlias
            ).map((option) => (
              <li key={option} className="px-4 py-2 text-sm">
                <div
                  className="block w-full"
                  onClick={() => onSelect(option.prompt)}
                >
                  {option.prompt}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Transition>
    </div>
  );
};

export default Typeahead;
