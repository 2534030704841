import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useQuery } from "react-query";
import Select from "react-select";
import { showError } from "../../common/showPopup";

function AddProjectModal({ show, onClose }) {
  const name = useRef();

  const [loading, setLoading] = useState(false);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [selectedSchema, setSelectedSchema] = useState();

  const {
    data: databasesRes,
    isLoading,
    refetch,
  } = useQuery(
    "getDatabases",
    async () => {
      try {
        return await axios.get(
          process.env.REACT_APP_SERVER_URL + "/integrations/databases"
        );
      } catch (err) {
        console.log(err);
      }
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  const databaseOptions = databasesRes?.data
    ? databasesRes.data.databases.map((database) => {
        return {
          label: database.name,
          options: database.schemas.map((schema) => {
            return {
              label: schema.name,
              value: { schema_name: schema.name, database_id: database._id },
            };
          }),
        };
      })
    : [];

  const customStyles = {
    container: (base) => ({
      ...base,
      color: "white",
    }),
    menu: (base) => ({
      ...base,
      color: "white",
      background: "black",
      border: "1px solid #4a4a4a",
    }),
    option: (base) => ({
      ...base,
      color: "white",
      background: "black",
      "&select": { color: "white" },

      "&:hover": { background: "#2563eb" },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    singleValue: (base) => ({
      ...base,
      color: "white",
    }),
  };

  const onAdd = () => {
    let error = false;
    if (!name.current.value) {
      setNameInvalid(true);
      error = true;
    }

    if (error) {
      return;
    }

    if (!selectedSchema) {
      showError("Please select a schema");
      return;
    }
    if (error) {
      return;
    }

    if (!selectedSchema) {
      showError("Please select a schema");
      return;
    }

    setLoading(true);

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/projects/create", {
        name: name.current.value,
        selected_schema: selectedSchema,
      })
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          const doc = res.data.projectDoc;

          onClose({
            name: doc.name,
            _id: doc._id,
            createdBy: doc["createdBy"],
            selected_schema: doc.selected_schema,
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        toast.error("Sorry, something went wrong");
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="black">
        <ModalHeader
          borderRadius="10px 10px 0 0"
          className="bg-black border-t border-l border-r border-[#4A4A4A] text-white "
        >
          Add Project
        </ModalHeader>
        <ModalCloseButton className="text-white" />
        <ModalBody
          bg="black"
          className=" text-white border-l border-r border-[#4A4A4A] "
        >
          <Stack spacing={4} marginY={"20px"}>
            <FormControl isInvalid={nameInvalid} id="name" isRequired>
              <FormLabel>Project Name</FormLabel>
              <Input borderColor="#4A4A4A" ref={name} type="text" />
              <FormErrorMessage>Name is required</FormErrorMessage>
            </FormControl>

            <Select
              menuPosition="fixed"
              className="dropdown"
              classNamePrefix="select"
              menuPlacement="bottom"
              placeholder="Select Schema"
              isLoading={isLoading}
              isClearable={true}
              isDisabled={isLoading}
              options={databaseOptions}
              value={selectedSchema}
              styles={customStyles}
              onChange={(e) => {
                setSelectedSchema(e);
              }}
            />
          </Stack>
        </ModalBody>
        <ModalFooter
          borderRadius="0px 0px 10px 10px"
          bg="black"
          className=" text-white border-b border-l border-r border-[#4A4A4A] "
        >
          <Button
            isLoading={loading}
            loadingText="Adding.."
            onClick={onAdd}
            sx={{
              backgroundColor: "#2563eb",
              color: "white",
              borderRadius: "md",
              _hover: {
                backgroundColor: "#1849b4",
              },
              _active: {
                backgroundColor: "#1849b4",
              },
              _focus: {
                boxShadow: "none",
              },
            }}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddProjectModal;
