import {
  Box,
  Button,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import Loading from "../common/Loading";
import { useQuery } from "react-query";
import AddUserModal from "./AddUserModal";
import axios from "axios";
import RemoveUserModal from "./RemoveUserModal";
import "./Team.css";

import yesIcon from "../../assets/yesIcon.svg";
import noIcon from "../../assets/noIcon.svg";
import removeIcon from "../../assets/removeIcon.svg";

function Team() {
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [removeUserEmail, setRemoveUserEmail] = useState("");
  const [removeUserId, setRemoveUserId] = useState("");
  const [locallyAddedUsers, setLocallyAddedUsers] = useState([]);
  const [locallyRemovedUsers, setLocallyRemovedUsers] = useState([]);

  const { data, isSuccess } = useQuery(
    "getTeam",
    async () => {
      try {
        return await axios.get(
          process.env.REACT_APP_SERVER_URL + "/users/team"
        );
      } catch (err) {
        console.log(err);
      }
    },
    {
      staleTime: 180000,
      cacheTime: 180000,
      refetchOnWindowFocus: false,
    }
  );

  // const data = {
  //   data: {
  //     team: [
  //       {
  //         name: "Alex",
  //         email: "alex@cb.com",
  //         is_association_verified: true,
  //       },
  //       {
  //         name: "James",
  //         email: "james@cb.com",
  //         is_association_verified: false,
  //       },
  //     ],
  //   },
  // };

  return (
    <Box>
      <RemoveUserModal
        show={showRemoveModal}
        email={removeUserEmail}
        id={removeUserId}
        onClose={(removedEmail) => {
          setShowRemoveModal(false);
          setRemoveUserId("");
          setRemoveUserEmail("");

          if (removedEmail) {
            setLocallyRemovedUsers([removedEmail, ...locallyRemovedUsers]);
          }
        }}
      />

      <div className="flex flex-row justify-between">
        <h1 className="text-3xl text-white mx-auto">Your Team</h1>
        <Button
          onClick={() => setShowModal(true)}
          sx={{
            backgroundColor: "#2563eb",
            color: "white",
            borderRadius: "md",
            _hover: {
              backgroundColor: "#1849b4",
            },
            _active: {
              backgroundColor: "#1849b4",
            },
            _focus: {
              boxShadow: "none",
            },
          }}
        >
          + Add User
        </Button>
      </div>
      <AddUserModal
        show={showModal}
        onClose={(newUser) => {
          if (newUser) {
            setLocallyAddedUsers([newUser, ...locallyAddedUsers]);
          }
          setShowModal(false);
        }}
      />
      <Loading isLoading={!data?.data?.team} />
      <Stack marginTop={"60px"}>
        {data?.data?.team && (
          <TableContainer>
            <Table
              variant={"simple"}
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 1em",
              }}
              className="teamTable"
            >
              <Thead className="teamTable--head ">
                <Tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  {!data.data.is_user && <Th>Accepted Invite</Th>}
                  {!data.data.is_user && <Th>Remove</Th>}
                </Tr>
              </Thead>
              <Tbody className="teamTable--body   text-[white]">
                {[
                  ...locallyAddedUsers.filter(
                    (e) => !locallyRemovedUsers.includes(e.email)
                  ),
                  ,
                  ...data.data.team.filter(
                    (e) => !locallyRemovedUsers.includes(e.email)
                  ),
                ].map((member, i) => {
                  return (
                    <Tr
                      key={`member-row-${i}`}
                      style={{ borderRadius: "10px" }}
                    >
                      <Td>{member.name}</Td>
                      <Td>{member.email}</Td>
                      {!data.data.is_user && (
                        <Td>
                          {member.is_association_verified ? (
                            <img src={yesIcon} alt="yes" className="icon" />
                          ) : (
                            <img src={noIcon} alt="no" className="icon" />
                          )}
                        </Td>
                      )}
                      {!data.data.is_user && (
                        <Td>
                          <img
                            src={removeIcon}
                            alt="remove icon"
                            className="icon"
                            onClick={() => {
                              setRemoveUserEmail(member.email);
                              setRemoveUserId(member._id);
                              setShowRemoveModal(true);
                            }}
                          />
                        </Td>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Box>
  );
}

export default Team;
