import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

function AddProjectModal({ show, onClose }) {
  const name = useRef();

  const [loading, setLoading] = useState(false);
  const [nameInvalid, setNameInvalid] = useState(false);

  const onAdd = () => {
    let error = false;
    if (!name.current.value) {
      setNameInvalid(true);
      error = true;
    }

    if (error) {
      return;
    }

    setLoading(true);

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/liveboards/create", {
        name: name.current.value,
      })
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          toast.success("LiveBoard created successfully");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          const doc = res.data.liveBoardDoc;

          onClose({
            name: doc.name,
            _id: doc._id,
            createdBy: doc["createdBy"],
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        toast.error("Sorry, something went wrong");
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="black">
        <ModalHeader
          borderRadius="10px 10px 0 0"
          className="bg-black border-t border-l border-r border-[#4A4A4A] text-white "
        >
          Add LiveBoard
        </ModalHeader>
        <ModalCloseButton className="text-white" />

        <ModalBody
          bg="black"
          className=" text-white border-l border-r border-[#4A4A4A] "
        >
          <Stack spacing={4} marginY={"20px"}>
            <FormControl isInvalid={nameInvalid} id="name" isRequired>
              <FormLabel>LiveBoard Name</FormLabel>
              <Input borderColor="#4A4A4A" ref={name} type="text" />
              <FormErrorMessage>Name is required</FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter
          borderRadius="0px 0px 10px 10px"
          bg="black"
          className=" text-white border-b border-l border-r border-[#4A4A4A] "
        >
          <button
            isLoading={loading}
            loadingText="Adding.."
            className="blueButton"
            onClick={onAdd}
          >
            Add
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddProjectModal;
