import { Button } from "@chakra-ui/react";
import React from "react";
import ApexWrapper from "../ApexWrapper/ApexWrapper";
import DraggableResizableElement from "../Drag/DraggableResizableElement";

const DraggablePage = () => {
  return (
    <div>
      <h1>This page has draggable element</h1>
      <DraggableResizableElement style={{ height: "348px" }} id={"chart2"}>
        <ApexWrapper
          xAxisData={["234", "234", "234", "232", "989", "898"]}
          chartType={"bar"}
          yAxisData={[234, 234, 234, 232, 989, 898]}
        />
      </DraggableResizableElement>

      <DraggableResizableElement id={"dOne"}>
        <h2>Resizable</h2>
        <p>Hello there da yebba</p>
      </DraggableResizableElement>
      {/* <DraggableResizableElement id={"dTwo"}>
        <h2>Resizable</h2>
        <p>Hello there da yebba</p>
      </DraggableResizableElement> */}
    </div>
  );
};

export default DraggablePage;
