import React from "react";
import { useLocation } from "react-router-dom";
import Spreadsheet from "../../components/data/Spreadsheet";

function Explore() {
  let { state } = useLocation();
  return (
    <div className="flex">
      {state ? (
        <Spreadsheet
          tableId={state.tableId}
          schemaId={state.schemaId}
          databaseId={state.databaseId}
          databaseName={state.databaseName}
          schemaName={state.schemaName}
          tableName={state.tableName}
        />
      ) : (
        <div className="text-white text-center w-full text-lg my-12">
          Please select a table from the sidebar, or use CMD-K to search
        </div>
      )}
    </div>
  );
}

export default Explore;
