import React from "react";
import { Navigate } from "react-router-dom";
import CommandPaletteContainer from "./components/commandPalette/CommandPalette";

function AuthGuard({ children, isLayout }) {
  // Super basic check - todo: add axios interceptor when 401
  let authed = localStorage.getItem("token") !== null;

  return authed ? (
    <>
      {!isLayout && <CommandPaletteContainer />} {children}
    </>
  ) : (
    <Navigate to="/login" replace />
  );
}

export default AuthGuard;
