import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Progress,
} from "@chakra-ui/react";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { showError } from "../../common/showPopup";
import "./Chat.css";

function DeleteModal({ show, onClose, value }) {
  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="black">
        <ModalHeader
          borderRadius="10px 10px 0 0"
          className="bg-black border-t border-l border-r border-[#4A4A4A] text-white text-center"
        >
          <h2 className="mt-8">
            Are you sure you want to delete schema {value["schema_name"]}
          </h2>
        </ModalHeader>
        <ModalCloseButton className="text-white" />

        <ModalBody className="bg-black  border-l border-r border-[#4A4A4A] text-white">
          <div className="flex justify-center mt-5 gap-7 w-full px-10">
            <Button
              className="mb-5 w-full "
              onClick={onClose}
              loadingText="Uploading..."
              sx={{
                backgroundColor: "#2563eb",
                color: "white",
                borderRadius: "md",
                _hover: {
                  backgroundColor: "#1849b4",
                },
                _active: {
                  backgroundColor: "#1849b4",
                },
                _focus: {
                  boxShadow: "none",
                },
              }}
            >
              No
            </Button>

            <Button
              className="mb-5 w-full "
              loadingText="Uploading..."
              onClick={onClose}
              sx={{
                backgroundColor: "#2563eb",
                color: "white",
                borderRadius: "md",
                _hover: {
                  backgroundColor: "#1849b4",
                },
                _active: {
                  backgroundColor: "#1849b4",
                },
                _focus: {
                  boxShadow: "none",
                },
              }}
            >
              Yes
            </Button>
          </div>
        </ModalBody>

        <ModalFooter
          borderRadius="0px 0px 10px 10px"
          className="bg-black border-b border-l border-r border-[#4A4A4A] text-white"
        ></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DeleteModal;
