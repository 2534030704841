import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
} from "@chakra-ui/react";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const [loading, setLoading] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [loginError, setLoginError] = useState("");

  const email = useRef();
  const password = useRef();

  const navigate = useNavigate();

  const onLogin = () => {
    let error = false;
    if (!email.current.value) {
      setEmailInvalid(true);
      error = true;
    }

    if (!password.current.value) {
      setPasswordInvalid(true);
      error = true;
    }

    if (error) {
      return;
    }

    setLoading(true);

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/auth/login", {
        email: email.current.value,
        password: password.current.value,
      })
      .then((res) => {
        const {
          token,
          name,
          email,
          uid,
          associated,
          isTempPassword,
          show_integration,
          error,
        } = res.data;

        if (error) {
          setLoginError(error);
        } else {
          localStorage.setItem("token", token);
          localStorage.setItem("name", name);
          localStorage.setItem("email", email);
          localStorage.setItem("uid", uid);
          localStorage.setItem("associated", associated);

          // Set axios header to JWT
          axios.defaults.headers.common["Authorization"] = token;

          if (isTempPassword) {
            navigate("/change-password");
          } else if (show_integration) {
            navigate("/connect-data");
          } else {
            navigate("/");
          }
        }
      })
      .catch((error) => {
        toast.error("Email or password is incorrect");

        // toast.error("Sorry something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input isInvalid={emailInvalid} ref={email} type="email" />
              <FormErrorMessage>Your email is required</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={passwordInvalid} id="password">
              <FormLabel>Password</FormLabel>
              <Input ref={password} type="password" />
              <FormErrorMessage>Your password is required</FormErrorMessage>
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <p className="text-red-500 text-center w-full">{loginError}</p>
                {/* <Link color={"blue.400"}>Forgot password?</Link> */}
              </Stack>
              <Button
                isLoading={loading}
                loadingText="Signing in..."
                onClick={onLogin}
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Sign in
              </Button>
              <Stack>
                <Text align={"center"}>
                  Not registered?{" "}
                  <Link className="text-blue-500" to="/register">
                    Sign up
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Login;
