import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { showError } from "../../common/showPopup";
import "./Team.css";

function AddUserModal({ show, onClose }) {
  const name = useRef();
  const email = useRef();

  const [loading, setLoading] = useState(false);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const onAdd = () => {
    let error = false;
    if (!name.current.value) {
      setNameInvalid(true);
      error = true;
    }

    if (!email.current.value) {
      setEmailInvalid(true);
      error = true;
    }

    if (error) {
      return;
    }

    setLoading(true);

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/users/invite", {
        name: name.current.value,
        email: email.current.value,
      })
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          toast.success("User invited successfully");
          onClose({
            name: name.current.value,
            email: email.current.value,
            is_association_verified: false,
            _id: res.data,
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        toast.error("Sorry, something went wrong");
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="black">
        <ModalHeader
          borderRadius="10px 10px 0 0"
          className="bg-black border-t border-l border-r border-[#4A4A4A] text-white"
        >
          Add Teammate
        </ModalHeader>
        <ModalCloseButton className="text-white" />

        <ModalBody className="bg-black  border-l border-r border-[#4A4A4A] text-white">
          <Stack spacing={4} marginY={"20px"}>
            <FormControl isInvalid={nameInvalid} id="name" isRequired>
              <FormLabel display={"inline-block"}>Name</FormLabel>
              <Input borderColor="#4A4A4A" ref={name} type="text" />
              <FormErrorMessage>Name is required</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={emailInvalid} id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input borderColor="#4A4A4A" ref={email} type="email" />
              <FormErrorMessage>Email is required</FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter
          borderRadius="0px 0px 10px 10px"
          className="bg-black border-b border-l border-r border-[#4A4A4A] text-white"
        >
          <Button
            isLoading={loading}
            loadingText="Adding.."
            onClick={onAdd}
            sx={{
              backgroundColor: "#2563eb",
              color: "white",
              borderRadius: "md",
              _hover: {
                backgroundColor: "#1849b4",
              },
              _active: {
                backgroundColor: "#1849b4",
              },
              _focus: {
                boxShadow: "none",
              },
            }}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddUserModal;
