import "ka-table/style.css";
import React, { useEffect, useState } from "react";
import { DataType, Table, useTable, kaReducer } from "ka-table";
import { useQuery } from "react-query";
import axios from "axios";
import { ActionType, PagingPosition } from "ka-table/enums";
import { EditingMode, SortingMode } from "ka-table/enums";
import { showError } from "../../common/showPopup";

const dataArray = Array(10)
  .fill(undefined)
  .map((_, index) => ({
    column1: `column:1 row:${index}`,
    column2: `column:2 row:${index}`,
    column3: `column:3 row:${index}`,
    column4: `column:4 row:${index}`,
    id: index,
  }));

const tablePropsInit = {
  columns: [
    { key: "column1", title: "Column 1", dataType: DataType.String },
    { key: "column2", title: "Column 2", dataType: DataType.String },
    { key: "column3", title: "Column 3", dataType: DataType.String },
    { key: "column4", title: "Column 4", dataType: DataType.String },
  ],
  data: dataArray,
  editingMode: EditingMode.Cell,
  rowKeyField: "id",
  sortingMode: SortingMode.None,
};

function Spreadsheet({
  databaseName,
  schemaName,
  tableName,
  tableId,
  schemaId,
  databaseId,
}) {
  const [pageIndex, setPageIndex] = useState(0);
  const [pagesCount, setPagesCount] = useState(1);

  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const dispatch = (action) => {
    changeTableProps((prevState) => kaReducer(prevState, action));
    if (action.type === ActionType.UpdatePageIndex) {
      setPageIndex(action.pageIndex);
    }
  };

  const fetchTableData = async () => {
    const response = await axios.get(
      process.env.REACT_APP_SERVER_URL + "/data/table-data",
      {
        params: {
          tableId,
          schemaId,
          databaseId,
          pageNo: pageIndex,
        },
      }
    );
    return response.data;
  };

  const { data, isLoading, isError, error, refetch } = useQuery(
    ["tableData", tableId, schemaId, databaseId, pageIndex],
    fetchTableData,
    {
      refetchOnWindowFocus: false,
      onError: () => {
        showError("Sorry, something went wrong");
      },
    }
  );

  useEffect(() => {
    if (data?.data) {
      const oldTableProps = { ...tableProps };
      oldTableProps.data = data.data;

      if (data.data.length) {
        const newColumns = Object.keys(data.data[0]).map((key, index) => {
          return {
            key,
            colGroup: { style: { minWidth: 100 } },
            width: 200,
            title: key,
            style: { width: 150 },
          };
        });

        oldTableProps.columns = newColumns;
      }

      changeTableProps(oldTableProps);
    }

    if (data?.pagesCount) {
      setPagesCount(data.pagesCount);
    }
  }, [data]);

  useEffect(() => {
    setPageIndex(0);
  }, [tableId, schemaId, databaseId]);

  useEffect(() => {
    refetch(); // Refetch the data when pageIndex changes
  }, [pageIndex]);

  return (
    <div className="w-full">
      {!tableId || !schemaId || !databaseId ? (
        <div className="text-white text-center text-lg my-12">
          Please select a table from the sidebar, or use CMD-K to search
        </div>
      ) : null}
      <>
        {databaseName && schemaName && tableName && (
          <div className="text-white text-lg mb-6">
            {databaseName} / {schemaName} / {tableName}
          </div>
        )}
        <Table
          {...tableProps}
          dispatch={dispatch}
          loading={{
            enabled: isLoading,
            text: "Loading data",
          }}
          childComponents={{
            tableWrapper: {
              elementAttributes: () => ({ style: { maxHeight: 600 } }),
            },
          }}
          columnResizing={true}
          paging={{
            enabled: true,
            pageIndex,
            pageSize: 10,
            position: PagingPosition.Bottom,
            pagesCount: pagesCount,
          }}
          rowKeyField={"id"}
          editingMode={EditingMode.None}
          sortingMode={SortingMode.Single}
        />
      </>
    </div>
  );
}

export default Spreadsheet;
