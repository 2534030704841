import {
  Button,
  Collapse,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import Loading from "../common/Loading";
import Select from "react-select";
import { useEffect, useRef } from "react";
import { showError, showSuccess } from "../../common/showPopup";
import "./Integrations.css";

function useOutsideAlerter(ref, id, onClickOutside) {
  useEffect(() => {
    function handleOutsideClick(e) {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        e.target.id !== id
      ) {
        onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [ref]);
}

const EditableDiv = ({ text, id, updateDatabaseState, updateTableContext }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(text);

  useEffect(() => {
    setNewValue(text);
  }, [text]);

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, id, () => done());

  const done = () => {
    setIsEditing(false);
  };

  return (
    <input
      style={{
        border: "1.5px solid #4A4A4A",
        background: "transparent",
      }}
      autoFocus={false}
      maxLength={140}
      className={"w-full p-1 margin-0"}
      ref={wrapperRef}
      id={id}
      value={newValue}
      onChange={(e) => {
        setNewValue(e.target.value);

        if (updateDatabaseState) {
          updateDatabaseState(e.target.value);
        }

        if (updateTableContext) {
          updateTableContext(e.target.value);
        }
      }}
    ></input>
  );
};

const DatabaseConfig = ({
  refetch,
  database,
  selectedTable,
  selectedSchema,
}) => {
  const table = selectedSchema?.tables
    ? selectedSchema.tables.find((t) => t.name === selectedTable)
    : null;
  const columns = table?.columns ? table.columns : [];

  const [loading, setLoading] = useState(false);
  const [editedSchemas, setEditedSchemas] = useState();
  const [tableContext, setTableContext] = useState("");

  const updateDatabaseState = (colName, newValue) => {
    const schemas = editedSchemas || database.schemas;
    const editedSchemaIndex = schemas.findIndex(
      (schema) => schema.name === selectedSchema.name
    );
    const editedTableIndex = selectedSchema.tables.findIndex(
      (table) => table.name === selectedTable
    );

    const newTable = {
      name: selectedSchema.tables[editedTableIndex].name,
      columns: selectedSchema.tables[editedTableIndex].columns.map((column) => {
        if (column.name === colName) {
          return {
            ...column,
            context: newValue,
          };
        } else {
          return column;
        }
      }),
    };

    const editedSchema = schemas[editedSchemaIndex];
    editedSchema.tables[editedTableIndex] = newTable;

    schemas[editedSchemaIndex] = editedSchema;

    setEditedSchemas(schemas);
  };

  const save = () => {
    if (tableContext) {
      setLoading(true);
      axios
        .patch(
          process.env.REACT_APP_SERVER_URL + "/integrations/update-table",
          {
            databaseId: database._id,
            schemaId: selectedSchema._id,
            tableId: table._id,
            tableContext,
          }
        )
        .then(() => {
          showSuccess("Your changes were saved");
          refetch();
        })
        .catch((err) => {
          showError("Sorry, your changes could not be saved");
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (editedSchemas && editedSchemas.length) {
      setLoading(true);
      axios
        .patch(
          process.env.REACT_APP_SERVER_URL + "/integrations/update-schemas",
          {
            id: database._id,
            newSchemas: editedSchemas,
          }
        )
        .then(() => {
          showSuccess("Your changes were saved");
          refetch();
        })
        .catch((err) => {
          showError("Sorry, your changes could not be saved");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="bg-transparent border-t border-l border-r border-[#4A4A4A] shadow-md mt-12 rounded-sm my-2 p-2 text-white">
      <div className="grid grid-cols-1 gap-2 md:flex md:items-center md:justify-between">
        <h2 className="pl-6 text-xl font-semibold my-4">{database.name}</h2>
        <Button
          isLoading={loading}
          loadingText="Loading.."
          onClick={save}
          sx={{
            backgroundColor: "#2563eb",
            color: "white",
            borderRadius: "md",
            _hover: {
              backgroundColor: "#1849b4",
            },
            _active: {
              backgroundColor: "#1849b4",
            },
            _focus: {
              boxShadow: "none",
            },
          }}
        >
          Save
        </Button>
      </div>

      <div className="my-2" key={table?.name ? table.name : ""}>
        <h3 className="pl-6 my-2 font-semibold">
          {table?.name ? table.name : ""}
        </h3>

        <div className="w-full p-2 my-4">
          <p className="my-2">Table Context:</p>
          <EditableDiv
            updateTableContext={(newValue) => setTableContext(newValue)}
            text={tableContext ? tableContext : table?.context || ""}
            id={"table-context"}
          />
        </div>

        <Table variant={"simple"}>
          <Thead>
            <Tr>
              <Th style={{ color: "#2563eb" }}>Column name</Th>
              <Th style={{ color: "#2563eb" }}>Context</Th>
              <Th></Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {columns.map((col) => {
              return (
                <Tr key={col.name}>
                  <Td className="overflow-hideen">{col.name}</Td>
                  <Td colSpan={4}>
                    <EditableDiv
                      updateDatabaseState={(newValue) =>
                        updateDatabaseState(col.name, newValue)
                      }
                      text={col.context}
                      id={col.name + "-context"}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

function DbContext({ open, type }) {
  const [selectedDb, setSelectedDb] = useState();
  const [selectedSchema, setSelectedSchema] = useState();
  const [selectedTable, setSelectedTable] = useState();

  useEffect(() => {
    setSelectedDb();
    setSelectedSchema();
    setSelectedTable();
  }, [open]);

  const {
    data: databases,
    isLoading,
    refetch,
  } = useQuery(
    "databases",
    async () => {
      try {
        return axios.get(
          process.env.REACT_APP_SERVER_URL + "/integrations/databases",
          {
            params: {
              kind: type,
            },
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    {
      enabled: open,
      refetchOnWindowFocus: false,
    }
  );

  const customStyles = {
    container: (base) => ({
      ...base,
      color: "white",
    }),
    menu: (base) => ({
      ...base,
      color: "white",
      background: "transparent",
      border: "1px solid #4a4a4a",
    }),
    option: (base) => ({
      ...base,
      color: "white",
      background: "transparent",
      "&select": { color: "white" },

      "&:hover": { background: "#2563eb" },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    singleValue: (base) => ({
      ...base,
      color: "white",
    }),
  };
  return (
    <Collapse in={open} animateOpacity>
      {open && (
        <div className="relative my-4 min-h-[360px]">
          <Loading isLoading={isLoading} />

          {databases?.data?.databases ? (
            <>
              <div className="grid grid-cols-1 w-full gap-4 lg:flex lg:justify-between">
                <div className="w-80">
                  <p>Database</p>
                  <Select
                    className="dropdown"
                    value={selectedDb}
                    placeholder="Database"
                    onChange={(e) => setSelectedDb(e)}
                    options={databases.data.databases.map((db) => ({
                      label: db.name,
                      value: db,
                    }))}
                    isClearable={true}
                    styles={customStyles}
                  />
                </div>

                <div className="w-80">
                  <p>Schema</p>
                  <Select
                    className="dropdown"
                    placeholder="Schema"
                    value={selectedSchema}
                    onChange={(e) => setSelectedSchema(e)}
                    isDisabled={!selectedDb}
                    options={
                      selectedDb
                        ? selectedDb.value.schemas.map((schema) => ({
                            label: schema.name,
                            value: schema,
                            class: "test",
                          }))
                        : []
                    }
                    isClearable={true}
                    styles={customStyles}
                  />
                </div>

                <div className="right-0 w-80">
                  <p>Table</p>
                  <Select
                    className="dropdown"
                    isDisabled={!selectedSchema}
                    placeholder="Table"
                    value={selectedTable}
                    onChange={(e) => setSelectedTable(e)}
                    options={
                      selectedSchema
                        ? selectedSchema.value.tables.map((table) => ({
                            label: table.name,
                            value: table,
                          }))
                        : []
                    }
                    isClearable={true}
                    styles={customStyles}
                  />
                </div>
              </div>

              {selectedDb && selectedTable && (
                <DatabaseConfig
                  refetch={refetch}
                  key={selectedDb}
                  selectedTable={selectedTable?.label}
                  selectedSchema={selectedSchema?.value}
                  database={selectedDb?.value}
                />
              )}
            </>
          ) : null}
        </div>
      )}
    </Collapse>
  );
}

export default DbContext;
