import {
  Box,
  Button,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../common/Loading";
import { useQuery } from "react-query";
import AddProjectModal from "./AddLiveBoardsModal";
import axios from "axios";
import RemoveProjectModal from "./RemoveLiveBoardsModal";

import "./LiveBoards.css";
import dustbinIcon from "../../assets/dustbin.svg";

function Team() {
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [removeProjectName, setremoveProjectName] = useState("");
  const [removeProjectId, setremoveProjectId] = useState("");
  const [locallyAddedprojects, setlocallyAddedprojects] = useState([]);
  const [locallyRemovedProjects, setlocallyRemovedProjects] = useState([]);

  const { data, isSuccess } = useQuery(
    "getLiveBoards",
    async () => {
      try {
        const result = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/liveboards/allLiveBoards"
        );
        console.log(result);
        return result;
      } catch (err) {
        console.log(err);
      }
    },
    {
      staleTime: 180000,
      cacheTime: 180000,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Box>
      <RemoveProjectModal
        show={showRemoveModal}
        projectName={removeProjectName}
        id={removeProjectId}
        onClose={(removedProjectName, removeProjectId) => {
          setShowRemoveModal(false);
          setremoveProjectId("");
          setremoveProjectName("");

          if (removeProjectId && removedProjectName) {
            setlocallyRemovedProjects([
              removeProjectId,
              ...locallyRemovedProjects,
            ]);
          }
        }}
      />

      <div className="flex flex-row justify-between">
        <h1 className="text-3xl text-white mx-auto">Your LiveBoards</h1>
        <button
          className="blueButton "
          alignSelf="flex-end"
          onClick={() => setShowModal(true)}
        >
          + Create Saved Dashboard
        </button>
      </div>
      <AddProjectModal
        show={showModal}
        onClose={(newProject) => {
          if (newProject) {
            setlocallyAddedprojects([newProject, ...locallyAddedprojects]);
          }
          setShowModal(false);
        }}
      />
      <Loading isLoading={!data?.data?.liveboard} />
      <Stack marginTop={"60px"}>
        {data?.data?.liveboard && (
          <TableContainer>
            <Table variant={"simple"} className="projectsTable text-white ">
              <Thead className="projectsTable--head tableHeading ">
                <Tr className="tableHeading text-blue-500">
                  <Th className="tableHeading ">Saved Dashboard</Th>
                  <Th>Created by</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody className="projectsTable--body">
                {[
                  ...locallyAddedprojects.filter(
                    (e) => !locallyRemovedProjects.includes(e._id)
                  ),
                  ,
                  ...data.data.liveboard.filter(
                    (e) => !locallyRemovedProjects.includes(e._id)
                  ),
                ].map((project, i) => {
                  console.log(
                    "****",
                    project["createdBy"],
                    project.name,
                    project
                  );
                  return (
                    <Tr className="hoverProject" key={`member-row-${i}`}>
                      <Td style={{ padding: "8px" }}>
                        <Link
                          style={{
                            width: "100%",
                            display: "inline-block",
                            padding: "14px",
                          }}
                          to={
                            "/liveboardview#" + project.name + "-" + project._id
                          }
                        >
                          {project.name}
                        </Link>
                      </Td>

                      <Td>{project["createdBy"]}</Td>
                      <Td>
                        <img
                          src={dustbinIcon}
                          alt="Dustbin"
                          className="icon "
                          onClick={() => {
                            setremoveProjectName(project.name);
                            setremoveProjectId(project._id);
                            setShowRemoveModal(true);
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Box>
  );
}

export default Team;
