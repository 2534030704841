import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Slide,
  Fade,
  Select,
  useModalContext,
} from "@chakra-ui/react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";

import { useState } from "react";
import ApexWrapper from "../ApexWrapper/ApexWrapper";

export const getValueOfkey = (keyIndex, data) => {
  const values = data.map((x) => {
    return x[keyIndex];
  });
  return values;
};

export const filterColumns = (headers, data, dtype) => {
  //string,number
  return headers
    .map((d, i) => {
      return { name: d, index: i };
    })
    .filter((element) => {
      let result = getValueOfkey(element.index, data);
      return result.every((e) => typeof e === dtype);
    });
};

const ChartModal = ({
  isModalOpened,
  onClose,
  headers,
  data,
  handleAddToLiveBoard,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentChartType, setCurrentChartType] = useState("bar");
  const [columnSelectedForX, setColumnForX] = useState(null);
  const [columnSelectedForY, setColumnForY] = useState(null);
  const [chartXValues, setChartXValues] = useState([]);
  const [chartYValues, setChartYValues] = useState([]);

  useEffect(() => {
    if (columnSelectedForX && columnSelectedForY && currentChartType) {
      const Xvalues = getValueOfkey(columnSelectedForX, data);
      const Yvalues = getValueOfkey(columnSelectedForY, data);
      setChartXValues(Xvalues);
      setChartYValues(Yvalues);
      setCurrentSlide((c) => (c + 1) % 2);
    }
  }, [columnSelectedForX, columnSelectedForY, currentChartType]);

  function onChangeSlide(e) {
    setCurrentChartType(e.target.value);
  }

  return (
    <>
      <Modal isOpen={isModalOpened} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          sx={{
            width: "35rem",
            maxWidth: "auto",
            height: "auto",
            maxHeight: "auto",
          }}
        >
          <ModalHeader
            borderRadius="10px 10px 0 0"
            className=" bg-black border-t border-l border-r border-[#4A4A4A] text-white "
          >
            {currentSlide === 1 && (
              <ArrowLeftIcon
                style={{ color: "white" }}
                className="w-6 h-8 cursor-pointer"
                onClick={() => {
                  setCurrentSlide((currentSlide + 1) % 2);
                  setColumnForX(null);
                  setColumnForY(null);
                }}
              />
            )}
          </ModalHeader>
          <ModalCloseButton className="text-white" />
          <>
            {currentSlide === 0 && (
              <Fade in>
                <ModalBody
                  bg="black"
                  className=" text-white border-l border-r border-[#4A4A4A] "
                  animation={"ease-in-out"}
                  sx={{ flex: 10 }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <h2>How do you want the data to be presented In</h2>
                    <Select
                      className="placeholder"
                      value={currentChartType}
                      onChange={onChangeSlide}
                    >
                      <option
                        style={{
                          color: "white",
                          backgroundColor: "black",
                        }}
                        value="bar"
                      >
                        Bar
                      </option>
                      <option
                        style={{
                          color: "white",
                          backgroundColor: "black",
                        }}
                        value="line"
                      >
                        Line
                      </option>
                      <option
                        style={{
                          color: "white",
                          backgroundColor: "black",
                        }}
                        value="area"
                      >
                        Area
                      </option>
                      <option
                        style={{
                          color: "white",
                          backgroundColor: "black",
                        }}
                        value="pie"
                      >
                        Pie Chart
                      </option>
                      <option
                        style={{
                          color: "white",
                          backgroundColor: "black",
                        }}
                        value="donut"
                      >
                        Donut Chart
                      </option>
                    </Select>
                    {
                      <>
                        <Select
                          className={"placeholder"}
                          placeholder={
                            currentChartType === "donut" ||
                            currentChartType === "pie"
                              ? "Select Column for Series"
                              : "Select Column for Y Axis"
                          }
                          value={columnSelectedForY}
                          onChange={(e) => setColumnForY(e.target.value)}
                        >
                          {filterColumns(headers, data, "number").map(
                            (payload, i) => (
                              <option
                                key={`payload-${i}`}
                                style={{
                                  color: "white",
                                  backgroundColor: "black",
                                }}
                                value={payload.index}
                              >
                                {payload.name}
                              </option>
                            )
                          )}
                        </Select>
                        <Select
                          className={"placeholder"}
                          placeholder={
                            currentChartType === "donut" ||
                            currentChartType === "pie"
                              ? "Select Column for Labels"
                              : "Select Column for X Axis"
                          }
                          value={columnSelectedForX}
                          onChange={(e) => setColumnForX(e.target.value)}
                        >
                          {filterColumns(headers, data, "string").map(
                            (payload, i) => (
                              <option
                                key={`option-payload-${i}`}
                                style={{
                                  color: "white",
                                  backgroundColor: "black",
                                }}
                                value={payload.index}
                              >
                                {payload.name}
                              </option>
                            )
                          )}
                        </Select>
                      </>
                    }
                  </div>
                </ModalBody>
              </Fade>
            )}
            {currentSlide === 1 && (
              <Fade in>
                <ModalBody
                  bg="black"
                  className=" text-white border-l border-r border-[#4A4A4A] "
                  animation={"ease-in-out"}
                  sx={{ flex: 10 }}
                >
                  <div className="flex justify-end items-center px-5">
                    <button
                      className="p-1 rounded-md border"
                      onClick={() =>
                        handleAddToLiveBoard(
                          columnSelectedForX,
                          columnSelectedForY
                        )
                      }
                    >
                      Add to Live Boards
                    </button>
                  </div>
                  <p>
                    <ApexWrapper
                      xAxisData={chartXValues}
                      yAxisData={chartYValues}
                      chartType={currentChartType}
                    />
                  </p>
                </ModalBody>
              </Fade>
            )}
          </>

          <ModalFooter
            borderRadius="0px 0px 10px 10px"
            bg="black"
            className=" text-white border-b border-l border-r border-[#4A4A4A] "
          ></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ChartModal;
