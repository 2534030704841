import React, { useState, useEffect } from "react";
import { TableCellsIcon } from "@heroicons/react/24/outline";
import styles from "./SchemaHint.module.css";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import { useQuery } from "react-query";

function SchemaHint({ schema, show, setShow }) {
  const [selectedTable, setSelectedTable] = useState(null);

  const {
    data: databasesRes,
    isLoading,
    refetch,
  } = useQuery(
    "getDatabases",
    async () => {
      try {
        return await axios.get(
          process.env.REACT_APP_SERVER_URL + "/integrations/databases"
        );
      } catch (err) {
        console.log(err);
      }
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  const databases = databasesRes?.data?.databases;

  useEffect(() => {
    setSelectedTable(null);
    setShow(false);
  }, [schema]);

  const selectedSchema =
    schema && databases
      ? databases
          ?.find((e) => e?._id === schema?.value?.database_id)
          ?.schemas?.find((e) => e?.name === schema?.value?.schema_name)
      : null;

  const onTableClick = (table) => {
    setSelectedTable(table);
  };

  return (
    <>
      <Tooltip id="schema-hint-tooltip" />

      <div
        style={{ border: "1px solid #fff" }}
        className={`${styles.banner} ${
          show ? `${styles.show} visible` : "hidden"
        } text-white schema-hint-banner absolute w-11/12 right-0 md:right-6 lg:right-12 p-4 bottom-16`}
      >
        <div className={styles.database_explorer}>
          {selectedSchema && (
            <div className={styles.table_list}>
              {selectedSchema.tables.map((t) => (
                <div
                  className={
                    t.name === selectedTable?.name
                      ? "bg-slate-200 text-black"
                      : ""
                  }
                  key={t.name}
                  onClick={() => onTableClick(t)}
                >
                  {t.name}
                </div>
              ))}
            </div>
          )}

          {selectedTable && (
            <div className="overflow-scroll w-[80%]">
              <Table variant={"simple"}>
                <Thead>
                  <Tr>
                    <Th style={{ color: "#fff" }}>Column name</Th>
                    <Th style={{ color: "#fff" }}>Context</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {selectedTable.columns.map((col) => {
                    return (
                      <Tr key={col.name}>
                        <Td className="overflow-hideen">{col.name}</Td>
                        <Td className="overflow-hideen">{col.context}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </div>
          )}
        </div>
      </div>

      <button
        data-tooltip-id="schema-hint-tooltip"
        data-tooltip-content="View schema"
        disabled={!selectedSchema}
        onClick={() => setShow(!show)}
        className="disabled:cursor-not-allowed disabled:opacity-50 h-full blackDiv hover:scale-105 ease-in-out active:scale-95 hover:border-white"
      >
        <TableCellsIcon className="max-h-[40px] h-full text-white" />
      </button>
    </>
  );
}

export default SchemaHint;
