import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
} from "@chakra-ui/react";
import DraggableResizableElement from "../Drag/DraggableResizableElement";
import { Link } from "react-router-dom";
import { useState } from "react";
import ChatBlock from "../chat/ChatBlock";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ClockIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import "./liveboard.css";
import Refresh from "./Refresh";
import ApexWrapper from "../ApexWrapper/ApexWrapper";
import { toast } from "react-toastify";
import DraggableChart from "./DraggableChart";
import { filterColumns, getValueOfkey } from "../ChartModal/ChartModal";

const ChartRenderer = ({ dump, x, y }) => {
  const { data } = dump;
  console.log(data, "dump_from_renderer");

  return (
    <div className="flex flex-col">
      <div className="flex gap-2">
        <DraggableResizableElement
          style={{ backgroundColor: "#181818" }}
          id={simpleHash(JSON.stringify(data + "bar"))}
        >
          <ApexWrapper
            xAxisData={getValueOfkey(x, data)}
            yAxisData={getValueOfkey(y, data)}
            chartType={"bar"}
          />
        </DraggableResizableElement>
        <DraggableResizableElement
          style={{ backgroundColor: "#181818" }}
          id={simpleHash(JSON.stringify(data + "pie"))}
        >
          <ApexWrapper
            xAxisData={getValueOfkey(x, data)}
            yAxisData={getValueOfkey(y, data)}
            chartType={"pie"}
          />
        </DraggableResizableElement>
      </div>
    </div>
  );
};

export const simpleHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    let char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash.toString();
};

function LiveBoardsView() {
  const [chats, setChats] = useState([]);
  const [liveBoardChat, setLiveBoardChat] = useState([]);
  const [chartPairs, setChartPairs] = useState([]);
  const [metaData, setMetaData] = useState(null);
  const [refreshTimes, setRefreshTimes] = useState(false);
  let location = useLocation();

  const rebootBoard = () => {
    let liveBoard_id = location.hash.slice(location.hash.indexOf("-") + 1);
    const user_id = localStorage.getItem("uid");

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/liveboards/getLiveBoard", {
        liveBoard_id,
        user_id,
      })
      .then((res) => {
        console.log("*****");
        console.log("response_from_lb", res.data);
        setChartPairs(res.data.liveBoard.savedChartPairs);
        setMetaData(res.data.liveBoard);
        setRefreshTimes(res.data.timeProcessed);
        const liveChats = res.data.liveBoard.chats.map((x) => {
          x.dump = JSON.parse(x.dump);
          return x;
        });
        console.log("hey", liveChats);
        setLiveBoardChat(liveChats);

        // }
      })
      .catch((err) => {
        toast.error("Sorry, something went wrong");
      });
  };

  useEffect(() => {
    console.log("rebooting board");
    rebootBoard();
  }, [location]);

  const hms = function (d) {
    const finalSec = d / 1000;
    if (finalSec < 60) {
      return "Updated " + Math.ceil(finalSec) + " seconds ago";
    }
    if (finalSec >= 60 && finalSec < 3600) {
      return "Updated " + Math.ceil(finalSec / 60) + " minutes ago";
    }
    if (finalSec >= 3600) {
      return "Updated " + Math.ceil(finalSec / 3600) + " hours ago";
    }
  };

  useEffect(() => {
    const allScrollNodes = document.querySelectorAll(".scrollContainer");
    allScrollNodes.forEach((node) => {
      node.scrollTo({
        top: node.scrollHeight,
        behavior: "smooth",
      });
    });
  }, [liveBoardChat]);

  return (
    <Flex minH={"100vh"} direction={"column"} bg={"transparent"}>
      {liveBoardChat && refreshTimes ? (
        <Refresh
          updatingText={"Updating"}
          updatedText={"Dashboards updated"}
          timeInSeconds={60}
        />
      ) : null}
      <h1 className="text-3xl font-semibold my-3 text-white">
        {(metaData && metaData.name) || "Please wait.."}
      </h1>
      {liveBoardChat.map((liveBoard) => {
        return (
          <div className="w-full" style={{ width: "100%", height: "100%" }}>
            {console.log("liveboard_data", liveBoard)}
            <DraggableResizableElement
              id={simpleHash(liveBoard["_id"])}
              className="scrollContainer"
              style={{
                height: "400px",
                overflow: "scroll",
                padding: "2rem",
                backgroundColor: "#181818",
                border: "1px solid #4a4a4a",
                marginBottom: "3rem",
                marginTop: "1rem",
                borderRadius: "8px",
              }}
            >
              {liveBoard.dump.map((chat, i) => {
                console.log("hey", chat);
                return chat.ready ? (
                  <>
                    <ChatBlock
                      key={`chat-block-${i}`}
                      chat={chat}
                      index={i}
                      isShared={true}
                      isVerified={true} // TODO: change to dynamic values
                      disableOptions={true}
                    />
                  </>
                ) : null;
              })}
              <br></br>
              <br></br>
              <br></br>
              <div className="d-flex">
                <ClockIcon className="w-5 h-5 bgz-white spaced" />
                <p className="text-white">
                  {hms(+new Date() - liveBoard.lastUpdated)}
                </p>
              </div>
            </DraggableResizableElement>
          </div>
        );
      })}
      {chartPairs.map((pair, i) => {
        if (pair.dump && pair.x && pair.y) {
          return (
            <>
              <ChartRenderer
                dump={JSON.parse(pair.dump)}
                x={pair.x}
                y={pair.y}
              />
            </>
          );
        }
        return <></>;
      })}
    </Flex>
  );
}

export default LiveBoardsView;
