import React from "react";
import { ClockIcon } from "@heroicons/react/24/solid";
import "./AuditLog.css";
function AuditLogs({ logs }) {
  const capitalizeLetter = (value) => {
    return `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`;
  };

  return (
    <div className="AuditContainer text-white">
      <h1 className="mb-[100px] font-medium text-2xl">Audit Logs</h1>

      {logs.map((log, index) => {
        //                 const newDate = new Date(1680520306184);
        const currentDate = new Date();
        //                 const currentYear = currentDate.getFullYear();
        //                 const currentMonth = currentDate.getMonth() + 1;
        //                 const currentDay = currentDate.getDate();

        //                 // Get year, month and day from newDate
        //                 const year = newDate.getFullYear();
        //                 const month = newDate.getMonth() + 1;
        //                 const day = newDate.getDate();
        //                 const today = new Date();
        //                 {
        //                     /* const date = new Date("2023-04-03"); */
        //                 }

        //                 const options = {
        //                     month: "long",
        //                     day: "2-digit",
        //                     year: "numeric",
        //                 };
        //                 const todayFormatted = `${month}/${day}/${year}`;
        //                 {
        //                     /* const formattedDate = date.toLocaleDateString("en-US", options); */
        //                 }
        //                 {
        //                     /* console.log(`Current Date: ${currentMonth}/${currentDay}/${currentYear}`);
        //    console.log(`Date from newDate: ${month}/${day}/${year}`); */
        //                 }
        //                 console.log(currentDate);
        // const slicedDate = new Date(1680520306184) + "";
        //                 const cutDate = slicedDate.slice(4, 15);

        return (
          <div key={index} className="flex  mb-10">
            <div className="w-1/4 flex justify-start ml-10 ">
              <h1 className="pt-5 text-sm font-semibold">
                {log.mainDate === currentDate.toDateString() ? (
                  <div className="text-sm font-semibold">
                    <p className="text-sm font-semibold mb-2">TODAY</p>
                    <p>
                      <p className="font-semibold">
                        {log.mainDate.slice(4, 15)}
                      </p>
                    </p>
                  </div>
                ) : (
                  <p className="font-semibold">{log.mainDate.slice(4, 15)}</p>
                )}
              </h1>
            </div>
            <div className="flex-col flex w-full gap-5">
              {log.events
                .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
                .map((ev) => (
                  <div className="w-3/4  px-10 border-[#2563EB] border-solid border-l-4 py-5 box">
                    <p className="mb-5 font-medium">
                      {`${ev.createdBy} ${capitalizeLetter(ev.operation)}`}
                    </p>
                    <div className="flex ">
                      <ClockIcon className="w-5 mr-3" />

                      <p className="font-medium">
                        {new Date(ev.trackDate).toString().slice(0, 25)}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AuditLogs;
