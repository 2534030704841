import React, { useState, useRef, useEffect } from "react";
import { Spinner } from "@chakra-ui/react";
function Refresh({ updatedText, updatingText, timeInSeconds }) {
  const [loading, setLoading] = useState(true);

  const [timer, setTimer] = useState("");
  const Ref = useRef(null);

  function reload() {
    window.location.reload();
  }

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  function displayTime(timer) {
    const finalTimer = timer.slice(6);

    if (finalTimer === "00") {
      setLoading(false);
    }
    return finalTimer;
  }

  const clearTimer = (e) => {
    setTimer(`00:00:${timeInSeconds}`);

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + timeInSeconds);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  return (
    <div>
      <div className="bg-transparent border border-[#4a4a4a] rounded-xl p-5  align-middle fixed w-[240px] bottom-[130px] left-[10px]">
        {" "}
        <div className="flex text-white ">
          {loading ? (
            <h1 className="mr-5">{updatingText}</h1>
          ) : (
            <h1 className="mr-5">{updatedText}</h1>
          )}
          {loading ? <Spinner className="text-white" /> : ""}
        </div>
        <div>
          {loading ? (
            <h2 className="text-[#666666] mt-3">
              {displayTime(timer)} seconds left
            </h2>
          ) : (
            <h2
              onClick={reload}
              className="text-[#2563eb] mt-3 underline  cursor-pointer"
            >
              Refresh Page
            </h2>
          )}
        </div>
      </div>
    </div>
  );
}

export default Refresh;
