import React, { useState, useEffect } from "react";

import "./sidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";

import {
  ArrowsRightLeftIcon,
  ArrowLeftOnRectangleIcon,
  DocumentIcon,
  FolderIcon,
  Bars3BottomLeftIcon,
  UsersIcon,
  ChatBubbleLeftRightIcon,
  CircleStackIcon,
  TableCellsIcon,
  BookOpenIcon,
} from "@heroicons/react/24/outline";
import {
  Sidebar as ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";

function Sidebar() {
  let location = useLocation();
  const navigate = useNavigate();
  const [projects, setAllProjects] = useState([]);
  const [liveBoards, setLiveBoards] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [selectedSchema, setSelectedSchema] = useState(null);

  const handleDatabaseClick = (database) => {
    setSelectedDatabase(database === selectedDatabase ? null : database);
  };

  const handleSchemaClick = (schema) => {
    setSelectedSchema(schema === selectedSchema ? null : schema);
  };

  const {
    data: databasesRes,
    isLoading,
    refetch,
  } = useQuery(
    "getDatabases",
    async () => {
      try {
        return await axios.get(
          process.env.REACT_APP_SERVER_URL + "/integrations/databases"
        );
      } catch (err) {
        console.log(err);
      }
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  const databases = databasesRes?.data?.databases;

  useEffect(() => {
    (async () => {
      const result = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/projects/allProjects"
      );
      if (result.data && result.data.projects && result.data.projects.length) {
        setAllProjects(result.data.projects);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const result = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/liveboards/allLiveBoards"
      );
      if (
        result.data &&
        result.data.liveboard &&
        result.data.liveboard.length
      ) {
        setLiveBoards(result.data.liveboard);
      }
    })();
  }, []);

  return (
      <div
          className="fixed h-[100vh] w-32  sm:w-[260px]  top-0 left-0 shadow overflow-auto text-sm"
          style={{ background: "#181818", color: "white" }}
      >
          <ProSidebar
              backgroundColor={"#181818"}
              style={{ color: "white", height: "90%" }}
              width="100%"
          >
              <div className="text-3xl sm:text-4xl font-medium pt-5 mb-16 text-center">
                  <Link to={"/"}>Layerup</Link>
              </div>

              <Menu
                  menuItemStyles={{
                      button: {
                          "&": {
                              background: "#181818",
                              color: "#white",
                          },
                          "&:hover": {
                              background: "#343434",
                              color: "#3B82F6",
                          },
                          "&:focus": {
                              background: "#343434",
                              color: "#3B82F6",
                          },
                      },
                  }}
                  subMenuStyles={{
                      button: {
                          "&": {
                              background: "#181818",
                              color: "#white",
                          },
                          "&:hover": {
                              background: "#343434",
                              color: "#3B82F6",
                          },
                          "&:focus": {
                              background: "#343434",
                              color: "#3B82F6",
                          },
                      },
                  }}
              >
                  <SubMenu
                      label="Projects"
                      icon={<UsersIcon className="w-6 h-6" />}
                      className={
                          location.pathname === "/projects"
                              ? "bg-backgroundSelected text-textblue"
                              : ""
                      }
                      component={<Link to="/projects" />}
                  >
                      {projects.map((doc, i) => {
                          return (
                              <MenuItem
                                  key={"project-" + i}
                                  icon={<DocumentIcon className="w-6 h-6" />}
                                  className={
                                      location.pathname + location.hash ===
                                      "/?project" + doc._id
                                          ? "bg-backgroundSelected text-textblue"
                                          : ""
                                  }
                                  component={
                                      <Link to={"/?project=" + doc._id} />
                                  }
                              >
                                  {doc.name}
                              </MenuItem>
                          );
                      })}
                  </SubMenu>
                  <SubMenu
                      label="Saved Dashboards"
                      icon={<FolderIcon className="w-6 h-6" />}
                      className={
                          location.pathname === "/liveboards"
                              ? "bg-backgroundSelected text-textblue"
                              : ""
                      }
                      component={<Link to="/liveboards" />}
                  >
                      {liveBoards.map((doc, i) => {
                          return (
                              <MenuItem
                                  key={"liveboard-" + i}
                                  icon={
                                      <ChatBubbleLeftRightIcon className="w-6 h-6" />
                                  }
                                  className={
                                      location.pathname + location.hash ===
                                      "/#" + doc.name
                                          ? "bg-backgroundSelected text-textblue"
                                          : ""
                                  }
                                  component={
                                      <Link
                                          to={
                                              "/liveboardview#" +
                                              doc.name +
                                              "-" +
                                              doc._id
                                          }
                                      />
                                  }
                              >
                                  {doc.name}
                              </MenuItem>
                          );
                      })}
                  </SubMenu>

                  <MenuItem
                      icon={<ArrowsRightLeftIcon className="w-6 h-6" />}
                      className={
                          location.pathname === "/integrations"
                              ? "bg-backgroundSelected text-textblue"
                              : ""
                      }
                      component={<Link to="/integrations" />}
                  >
                      Integrations
                  </MenuItem>
          {/* <MenuItem
            icon={<Bars3BottomLeftIcon className="w-6 h-6" />}
            className={
              location.pathname === "/dashboardAnalysis"
                ? "bg-backgroundSelected text-textblue"
                : ""
            }
            component={<Link to="/dashboardAnalysis" />}
          >
            Dashboard Analysis
          </MenuItem> */}

          <SubMenu
            label="Explore data"
            style={{ backgroundColor: "#181818" }}
            icon={<CircleStackIcon className="w-6 h-6" />}
            className={
              location.pathname === "/data"
                ? "bg-backgroundSelected text-textblue"
                : ""
            }
            component={<Link to="/data" />}
          >
            <ul
              style={{ backgroundColor: "#181818" }}
              className="overflow-y-scroll max-h-[360px]"
            >
              {databases &&
                databases.map((database) => (
                  <li className="ml-4" key={database.name}>
                    <button
                      className="flex w-full text-left p-4"
                      onClick={() => handleDatabaseClick(database.name)}
                    >
                      <CircleStackIcon className="w-4 h-4 mr-2" />
                      <span className="ml-4">
                        {database?.name?.length > 15
                          ? database.name.slice(0, 15) + "..."
                          : database.name}
                      </span>
                    </button>
                    {selectedDatabase === database.name && (
                      <ul className="ml-4">
                        {database.schemas.map((schema) => (
                          <li key={schema.name}>
                            <button
                              className="flex items-center w-full text-left mb-2 p-4"
                              onClick={() => handleSchemaClick(schema.name)}
                            >
                              {schema.name}
                            </button>
                            {selectedSchema === schema.name && (
                              <ul className="ml-4">
                                {schema.tables.map((table) => (
                                  <li key={schema.name + table.name}>
                                    <Link
                                      className="flex p-1 items-center mb-2"
                                      to={"/data"}
                                      state={{
                                        databaseId: database._id,
                                        schemaId: schema._id,
                                        tableId: table._id,
                                        databaseName: database.name,
                                        schemaName: schema.name,
                                        tableName: table.name,
                                      }}
                                    >
                                      <TableCellsIcon className="w-4 h-4 mr-2" />
                                      <span className="truncate">
                                        {table.name}
                                      </span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
            </ul>
          </SubMenu>
          <MenuItem
            icon={<UsersIcon className="w-6 h-6" />}
            className={
              location.pathname === "/team"
                ? "bg-backgroundSelected text-textblue"
                : ""
            }
            component={<Link to="/team" />}
          >
            Team
          </MenuItem>
          <MenuItem
            icon={<BookOpenIcon className="w-6 h-6" />}
            className={
              location.pathname === "/dictionary"
                ? "bg-backgroundSelected text-textblue"
                : ""
            }
            component={<Link to="/dictionary" />}
          >
            Dictionary
          </MenuItem>
          <MenuItem
            icon={<Bars3BottomLeftIcon className="w-6 h-6" />}
            className={
              location.pathname === "/logs"
                ? "bg-backgroundSelected text-textblue"
                : ""
            }
            component={<Link to="/logs" />}
          >
            Audit Logs
          </MenuItem>
        </Menu>
      </ProSidebar>
      <div className="absolute text-left bottom-0 h-[100px] w-full flex pl-8 pr-2 gap-4 items-center">
        <div
          title="Logout"
          className="cursor-pointer delay-100 hover:scale-110"
          onClick={() => {
            localStorage.removeItem("token");
            navigate("/login");
          }}
        >
          <ArrowLeftOnRectangleIcon className="w-6 h-6" />
        </div>
        <div>
          <p style={{ fontWeight: "bold", color: "#3B82F6" }}>
            {localStorage.getItem("name")}
          </p>
          <p className="hidden sm:block truncate text-textblue">
            {localStorage.getItem("email")?.length > 20
              ? localStorage.getItem("email").slice(0, 15) + "..."
              : localStorage.getItem("email")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
