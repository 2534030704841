import { useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { savePrompt } from "./DetailedPromptSavingModal";
import { useEffect } from "react";

const CenterPromptSavingModal = ({ show, onClose, currentPrompt }) => {
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [alias, setAlias] = useState("");

  useEffect(() => {
    setPrompt(currentPrompt);
  }, [currentPrompt]);

  return (
    <div>
      <Modal isOpen={show} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="black">
          <ModalHeader
            borderRadius="10px 10px 0 0"
            className="bg-black border-t border-l border-r border-[#4A4A4A] text-white "
          >
            Save Prompt
          </ModalHeader>
          <ModalCloseButton className="text-white" />
          <ModalBody
            bg="black"
            className=" text-white border-l border-r border-[#4A4A4A] "
          >
            <Stack spacing={4} marginY={"20px"}>
              <FormControl id="prompt" isRequired>
                <FormLabel>Prompt</FormLabel>
                <Input
                  borderColor="#4A4A4A"
                  type="text"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                />
                <FormErrorMessage>prompt is required</FormErrorMessage>
              </FormControl>

              <FormControl id="alias" isRequired>
                <FormLabel>Alias(optional)</FormLabel>
                <Input
                  borderColor="#4A4A4A"
                  type="text"
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter
            borderRadius="0px 0px 10px 10px"
            bg="black"
            className=" text-white border-b border-l border-r border-[#4A4A4A] "
          >
            <Button
              onClick={async () => {
                setLoading(true);
                await savePrompt(prompt, alias);
                setLoading(false);
                onClose();
              }}
              isLoading={loading}
              loadingText="Adding.."
              sx={{
                backgroundColor: "#2563eb",
                color: "white",
                borderRadius: "md",
                _hover: {
                  backgroundColor: "#1849b4",
                },
                _active: {
                  backgroundColor: "#1849b4",
                },
                _focus: {
                  boxShadow: "none",
                },
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CenterPromptSavingModal;
