import React, { useState } from "react";
import ChatBase from "../chat/ChatBase";

function Home() {
  return (
    <div>
      <ChatBase />
    </div>
  );
}

export default Home;
