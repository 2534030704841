import React, { useState, useRef } from "react";
import paper from "../../assets/paper.svg";
import { Transition } from "@headlessui/react";

import {
  Textarea,
  InputGroup,
  InputRightElement,
  Button,
} from "@chakra-ui/react";

import ResizeTextarea from "react-textarea-autosize";

import DraggableResizableElement from "../Drag/DraggableResizableElement";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { toast } from "react-toastify";
import "./Chat.css";
import { TypeAnimation } from "react-type-animation";
import { useEffect } from "react";
import UpgradePopup from "../upgrade/UpgradePopup";

const LoadingFeedback = () => {
  return (
    <div className="chat-input-loading-tooltip-container">
      <div className="tooltip-content">
        <TypeAnimation
          sequence={[
            "Generating query...",
            Math.floor(Math.random() * (3500 - 2500)) + 2500,
            "Connecting to DB...",
            Math.floor(Math.random() * (6000 - 3000)) + 3000,
            ,
            "Preparing results...",
            ,
          ]}
          style={{ fontSize: "1.1em" }}
          wrapper="p"
          repeat={0}
        />
      </div>
    </div>
  );
};

const AutoResizeTextArea = React.forwardRef((props, ref) => {
  return (
    <>
      <Textarea
        minH="unset"
        overflow="hidden"
        w="100%"
        resize="none"
        ref={ref}
        className="blackDiv"
        minRows={1}
        maxLength={250}
        as={ResizeTextarea}
        {...props}
        // bgColor="transparent"
        // borderColor={"gray.400"}
      />
    </>
  );
});

const cancelTokenSource = axios.CancelToken.source();

const formatTime = (time) => {
  const minutes = Math.floor(time / 60000);
  const seconds = Math.floor((time % 60000) / 1000);
  const milliseconds = time % 1000;
  return `${minutes}:${String(seconds).padStart(2, "0")}.${String(
    milliseconds
  ).padStart(3, "0")}`;
};

function ChatInput({
  addChat,
  inputVal,
  setInputVal,
  sessionId,
  setSessionId,
  selectedSchema,
  options,
  setMostRecentQuery,
  setChatInputLoading,
}) {
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isRunning]);

  const toggleTimer = (value) => {
    if (value && !isRunning) {
      setTime(0);
    }
    setIsRunning(value);
  };

  const handleCancelClick = () => {
    cancelTokenSource.cancel("Request canceled by the user.");
  };

  const submitQuestion = () => {
    if (!inputVal) return;

    toggleTimer(true);
    setLoading(true);
    setChatInputLoading(true);

    setMostRecentQuery(inputVal);
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/questions/question",
        {
          text: inputVal,
          session_id: sessionId,
          selected_schema: selectedSchema?.value?.schema_name,
          selected_db: selectedSchema?.value?.database_id,
        },
        {
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        if (res.data?.upgrade) {
          setShowUpgradeModal(true);
        } else {
          if (res.data?.session_id) {
            setSessionId(res.data.session_id);
          }

          addChat([
            {
              id: res.data.id,
              text: inputVal.trim(),
              isQuestion: true,
              ready: true,
            },
            {
              id: res.data.id,
              text: res.data?.answer?.answer?.trim() || "",
              isQuestion: false,
              ready: true,
              chartData: res.data?.answer?.chartData,
              tableData: res.data?.answer?.tableData,
              options: res.data?.answer?.options,
              answerId: res.data?.answer?.sqlAnswerId,
              sqlAnswer: res.data?.answer?.sqlAnswer,
            },
          ]);

          setInputVal("");
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else {
          toast.error("Sorry, something went wrong");
          console.log(err);
        }
      })
      .finally(() => {
        toggleTimer(false);
        setLoading(false);
      });
  };

  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const filteredOptions =
    options.filter(
      (option) =>
        query.length !== 0 &&
        option.prompt.toLowerCase().indexOf(query.toLowerCase()) > -1
    ) || [];
  const filteredOptionsBasedAlias =
    options.filter(
      (option) => option.alias.toLowerCase().indexOf(query.toLowerCase()) > -1
    ) || [];

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.key === "Enter") {
      event.preventDefault();
      return submitQuestion();
    }
  };
  const [size, setSize] = useState({ width: 200, height: 200 });
  const handleResize = (newSize) => {
    setSize(newSize);
  };

  return (
    <div className="w-[50vw]">
      <UpgradePopup
        show={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
      />
      <div className="w-full justify-around flex gap-2 items-center">
        {loading && (
          <Button
            // size={"sm"}
            bgColor={"gray.500"}
            _hover={{ bg: "gray.600" }}
            colorScheme={"white"}
            margin={0}
            className="bg-white"
            onClick={handleCancelClick}
          >
            Cancel request
          </Button>
        )}

        {/* <div className="ml-24 text-white text-lg font-semibold">
          Time: {formatTime(time)}
        </div> */}

        <div className="flex flex-col w-full h-30">
          <InputGroup className="w-[50vw] flex flex-col autocomplete" size="md">
            <AutoResizeTextArea
              disabled={loading || !selectedSchema}
              placeholder={
                !selectedSchema ? "Please select a schema to started" : ""
              }
              onKeyDown={handleKeyDown}
              onKeyUp={(e) => {
                if (e.key === "ArrowRight") {
                  setInputVal(filteredOptions[0].prompt || "");
                }
              }}
              onChange={(e) => {
                setInputVal(e.target.value);
                setQuery(e.target.value);
                setIsOpen(true);
              }}
              value={inputVal}
            />
            {filteredOptions.length !== 0 && (
              <div className="absolute top-[8px] left-[13px] pointer-events-none">
                <span className="text-grey -z-10">
                  {filteredOptions[0].prompt.substring(0, inputVal.length)}
                </span>
                <span className="text-[#808080] -z-20">
                  {filteredOptions[0].prompt.substring(inputVal.length)}
                </span>
              </div>
            )}
            <InputRightElement className="bottom-0" width="4.5rem">
              {loading && <LoadingFeedback />}

              <Button
                onClick={submitQuestion}
                h="1.75rem"
                size="sm"
                isLoading={loading}
                colorScheme="white"
                disabled={!inputVal || loading || !selectedSchema}
                bg="transparent"
                _hover={{ bg: "blue.800" }}
              >
                <img src={paper}></img>
              </Button>
            </InputRightElement>
          </InputGroup>
        </div>
      </div>
    </div>
  );
}

export default ChatInput;
