import React from "react";
import postgresLogo from "../../assets/logos/postgres.png";
import snowflakeLogo from "../../assets/logos/snowflake.png";
import mysqlLogo from "../../assets/logos/mysql.png";
import mongoLogo from "../../assets/logos/mongo.png";
import bigqueryLogo from "../../assets/logos/bigquery.png";
import ConnectDB from "../../components/integrations/ConnectDB";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const databases = [
  {
    name: "POSTGRESQL",
    type: "db",
    label: "PostgreSQL",
    img: postgresLogo,
  },
  {
    name: "SNOWFLAKE",
    type: "db",
    label: "Snowflake",
    img: snowflakeLogo,
  },
  {
    name: "MYSQL",
    type: "db",
    label: "MySQL",
    img: mysqlLogo,
  },
  {
    name: "MONGO",
    type: "db",
    label: "MongoDB",
    img: mongoLogo,
  },
  {
    name: "BIGQUERY",
    type: "db",
    label: "BigQuery",
    img: bigqueryLogo,
  },
];

const DatabaseTile = ({ label, imageUrl, name, navigate }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <ConnectDB
        type={name}
        hideButton
        overrideShowModal={show}
        parentOnClose={() => setShow(false)}
        parentOnDone={() => navigate("/projects")}
      />
      <button
        style={{
          borderColor: "#2563eb",
          borderWidth: 2,
        }}
        className="bg-white delay-100 hover:opacity-90 active:scale-95 ease-linear transition-all hover:scale-105 rounded-lg shadow-md hover:shadow-lg p-4"
        onClick={() => setShow(!show)}
      >
        <img
          className="mx-auto h-8 mb-2 object-cover"
          src={imageUrl}
          alt={`${label} logo`}
        />
        <h3 className="text-center mt-8 text-lg font-medium">{label}</h3>
      </button>
    </>
  );
};

function ConnectData() {
  const navigate = useNavigate();

  return (
    <div className="py-16 px-28 min-h-screen appLayout">
      <div>
        <h1 className="text-3xl text-white">Connect A Datasource</h1>
        <h2 className="text-lg text-white mt-6">
          To get started please connect your database or data warehouse
        </h2>
      </div>

      <div className="mt-12 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
        {databases.map((database) => {
          return (
            <DatabaseTile
              name={database.name}
              label={database.label}
              imageUrl={database.img}
              navigate={navigate}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ConnectData;
