import React, { useState } from "react";
import "./Team.css"
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import axios from "axios";
import { showError, showSuccess } from "../../common/showPopup";

function RemoveUserModal({ show, email, id, onClose }) {
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/users/remove", {
        associated_user_id: id,
      })
      .then((res) => {
        showSuccess("User removed successly");
        onClose(email);
      })
      .catch((error) => {
        showError("Sorry, something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
      <Modal isOpen={show} onClose={onClose}>
          <ModalOverlay />
          <ModalContent bg="black">
              <ModalHeader
                  borderRadius="10px 10px 0 0"
                  className="bg-black border-t border-l border-r border-[#4A4A4A] text-white"
              >
                  Remove Teammate
              </ModalHeader>
              <ModalCloseButton className="text-white" />

              <ModalBody className="bg-black  border-l border-r border-[#4A4A4A] text-white">
                  Are you sure you want to remove <b>{email}</b> from your team?
              </ModalBody>

              <ModalFooter
                  borderRadius="0px 0px 10px 10px"
                  className="bg-black border-b border-l border-r border-[#4A4A4A] text-white"
              >
                  <button onClick={onClose} className="mr-4 blackButton px-5">
                      Cancel
                  </button>
                  <Button
                      onClick={handleConfirm}
                      isLoading={loading}
                      loadingText="Removing..."
                      bg={"red.400"}
                      color={"white"}
                      _hover={{
                          bg: "red.500",
                      }}
                  >
                      Remove
                  </Button>
              </ModalFooter>
          </ModalContent>
      </Modal>
  );
}

export default RemoveUserModal;
