import React, { useEffect, useState, useCallback } from "react";
import { Box, Button } from "@chakra-ui/react";
import { useMergeLink } from "@mergeapi/react-merge-link";
import axios from "axios";
import ConnectBillingModal from "./ConnectBillingModal";
import ConnectDB from "./ConnectDB";
import "./Integrations.css";

function SlackIntegrations() {
  const [isConnected, setIsConnected] = useState(false);
  const [isSlackConnected, setIsSlackConnected] = useState(false);

  const [linkToken, setLinkToken] = useState(null);
  const onSuccess = useCallback((public_token) => {
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/exchangeForAccessKey", {
        publicToken: public_token,
      })
      .then((res) => {
        if (res.data) {
          setIsConnected(true);
          setLinkToken(null);
          axios
            .get(process.env.REACT_APP_SERVER_URL + "/customers")
            .then((res) => {});
        }
      });
  }, []);

  const postSlackMessage = async (message) => {
    axios.post(process.env.REACT_APP_SERVER_URL + "/sendSlackMessage", {
      message: "Hello from the website",
    });
  };

  const { open, isReady } = useMergeLink({
    linkToken,
    onSuccess,
  });

  useEffect(() => {
    if (isConnected) {
      axios
        .get(process.env.REACT_APP_SERVER_URL + "/customers")
        .then((res) => {});
    }
  }, [isConnected]);

  useEffect(() => {
    if (linkToken) open();
  }, [linkToken]);

  const checkConnection = async () => {
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/checkIntegration")
      .then((res) => {
        if (res.data) {
          setIsConnected(true);
        }
      });
  };

  const checkSlackConnection = async () => {
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/checkSlackIntegration")
      .then((res) => {
        if (res.data) {
          setIsSlackConnected(true);
        } else {
          setIsSlackConnected(false);
        }
      });
  };

  const genLink = async () => {
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/generateLink")
      .then((res) => {
        if (res.data) {
          setLinkToken(res.data);
        }
      });
  };

  useEffect(() => {
    checkConnection();
    checkSlackConnection();
  }, []);

  function getUid() {
    return localStorage.getItem("uid") || "";
  }

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <p className={"text-white"}>hi</p>
      {isSlackConnected ? (
        <a
          href={`https://slack.com/oauth/v2/authorize?client_id=4914389678481.4887234333895&state=${getUid()}&scope=chat%3Awrite%2Cgroups%3Awrite%2Cim%3Awrite%2Cusers%3Aread%2Cmpim%3Awrite&amp;user_scope=&amp;redirect_uri=https%3A%2F%2F15fb-49-205-80-252.in.ngrok.io%2Fslack%2Foauth_redirect&amp`}
        >
          <Button
            variant={"ghost"}
            bg={"black"}
            color={"white"}
            _hover={{
              bg: "black",
            }}
          >
            Add to slack
          </Button>
        </a>
      ) : (
        <Button
          variant={"ghost"}
          bg={"black"}
          color={"white"}
          _hover={{
            bg: "black",
          }}
          onClick={() => {
            alert("Please uninstall slack from your workspace");
          }}
        >
          Disconnect Slack
        </Button>
      )} */}
    </div>
  );
}

function Integrations() {
  // const [isConnected, setIsConnected] = useState(false);
  // const [billingModalOpen, setBillingModalOpen] = useState(false);

  // const [linkToken, setLinkToken] = useState(null);
  // const onSuccess = useCallback((public_token) => {
  //   axios
  //     .post("http://localhost:5001" + "/exchangeForAccessKey", {
  //       publicToken: public_token,
  //     })
  //     .then((res) => {
  //       if (res.data) {
  //         setIsConnected(true);
  //         setLinkToken(null);
  //         axios.get("http://localhost:5001" + "/customers").then((res) => {
  //           if (res.data) {
  //             // console.log("Customer info", res.data);
  //           }
  //         });
  //       }
  //     });
  // }, []);

  // const { open, isReady } = useMergeLink({
  //   linkToken,
  //   onSuccess,
  // });

  // useEffect(() => {
  //   if (isConnected) {
  //     axios.get("http://localhost:5001" + "/customers").then((res) => {
  //       if (res.data) {
  //         // console.log("Customer info", res.data);
  //       }
  //     });
  //   }
  // }, [isConnected]);
  // useEffect(() => {
  //   if (linkToken) open();
  // }, [linkToken]);

  // const checkConnection = async () => {
  //   axios.get("http://localhost:5001" + "/checkIntegration").then((res) => {
  //     if (res.data) {
  //       setIsConnected(true);
  //     }
  //   });
  // };

  // const genLink = async () => {
  //   axios.get("http://localhost:5001" + "/generateLink").then((res) => {
  //     if (res.data) {
  //       setLinkToken(res.data);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   checkConnection();
  // }, []);

  return (
    <Box>
      <div className="flex flex-row justify-between mb-12">
        <h1 className=" mx-auto text-3xl text-center text-white">
          Integrations
        </h1>
      </div>

      <div>
        <ConnectDB type={"SNOWFLAKE"} />
        <ConnectDB type={"POSTGRESQL"} />
        <ConnectDB type={"MYSQL"} />
        <ConnectDB type={"REDSHIFT"} />
        <ConnectDB type={"MONGODB"} />
        <ConnectDB type={"BIGQUERY"} />
        <SlackIntegrations />
      </div>
    </Box>
  );
}

export default Integrations;
