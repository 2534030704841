import React, { useState, useEffect, useRef } from "react";
import { Command } from "cmdk";
import "./linear.scss";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import axios from "axios";
import { showError } from "../../common/showPopup";
import Loading from "../common/Loading";
import { useNavigate } from "react-router-dom";

const CommandPaletteContainer = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [definition, setDefinition] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const down = (e) => {
      if (e.key === "k" && e.metaKey) {
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  useEffect(() => {
    setSearch("");
    setItems([]);
  }, [open]);

  const debounceTimeout = useRef(null);

  const fetchSuggestions = async (value) => {
    if (!value) {
      setItems([]);
      return;
    }

    setLoading(true);

    try {
      const res = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          "/embeddings/search-database-embeddings",
        {
          params: {
            searchQuery: value,
          },
        }
      );

      if (res.data) {
        const newItems = [];
        res.data.forEach((item) => {
          console.log(item);
          const newItem = { ...item };
          item.label =
            item?.label?.length > 140
              ? item?.label?.slice(0, 140) + "..."
              : item.label;
          newItems.push(newItem);
        });

        console.log(newItems);

        setItems([...newItems]);
      } else {
        console.log("No data");
        setItems([]);
      }
    } catch (error) {
      showError("Sorry, something went wrong");
      setItems([]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e;
    setSearch(value);

    // Clear any existing debounce timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new debounce timeout
    debounceTimeout.current = setTimeout(() => {
      fetchSuggestions(value);
    }, 300);
  };

  return (
    <Modal
      size={"lg"}
      isCentered={true}
      isOpen={open}
      onClose={() => setOpen(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <div className="linear text-white">
          <Command
            filter={() => {
              return true;
            }}
          >
            <Command.Input
              value={search}
              onValueChange={handleInputChange}
              autoFocus
              placeholder="Search for a table..."
            />
            {definition && (
              <div className="p-4 my-2">Definition: {definition}</div>
            )}
            <Command.List>
              {loading ? (
                <Command.Empty>
                  <Loading isLoading={true} />
                </Command.Empty>
              ) : items.length === 0 && search ? (
                <Command.Empty>No results found.</Command.Empty>
              ) : (
                items.map(
                  (
                    {
                      label,
                      databaseId,
                      schemaId,
                      tableId,
                      databaseName,
                      schemaName,
                      tableName,
                    },
                    i
                  ) => {
                    return (
                      <Command.Item
                        key={`label-${i}-${label}`}
                        onSelect={() => {
                          navigate("/data", {
                            state: {
                              databaseId,
                              schemaId,
                              tableId,
                              databaseName,
                              schemaName,
                              tableName,
                            },
                          });
                          setOpen(false);
                        }}
                      >
                        {label}
                      </Command.Item>
                    );
                  }
                )
              )}
            </Command.List>
          </Command>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default CommandPaletteContainer;
