import { Center, Spinner } from "@chakra-ui/react";
import React from "react";

function Loading({ isLoading }) {
  if (!isLoading) return null;

  return (
    <Center paddingY={"40px"}>
      <Spinner speed="0.75s" thickness="4px" size={"lg"} color="blue.700" />
    </Center>
  );
}

export default Loading;
