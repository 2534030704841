import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../menu/Sidebar";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { useDisclosure } from "@chakra-ui/react";
import "./AppLayout.css";

function AppLayout() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const betakey = localStorage.getItem("betakey");
  const masterBetaKey = "poqmlkysgbyqiolkhytrqhklsnxhyqqq";
  const [value, setValue] = useState("");

  useEffect(() => {
    if (betakey !== masterBetaKey) {
      //   onOpen();
    }
  }, []);

  return (
    <div className="pt-12 pr-10 pl-36 sm:pl-72 min-h-screen appLayout">
      <Modal closeOnOverlayClick={false} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter your exclusive betacode</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Input
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              mr={2}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                if (value === "poqmlkysgbyqiolkhytrqhklsnxhyqqq") {
                  localStorage.setItem("betakey", value);
                  onClose();
                } else {
                  toast.error("Sorry, you do not have access to layerup beta", {
                    toastId: "toast-error",
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 7500,
                  });
                }
              }}
            >
              Validate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Sidebar />
      <Outlet />
    </div>
  );
}

export default AppLayout;
