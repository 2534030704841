import React, { useMemo } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import Conversations from "./pages/Conversations/conversation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppLayout from "./components/layout/AppLayout";
import AuthGuard from "./AuthGuard";
import TeamPage from "./pages/team/team";
import ProjectsPage from "./pages/projects/projects";
import LiveBoardsPage from "./pages/liveboards/liveboards";
import LiveBoardViewPage from "./pages/liveboards/liveboardsview";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import { toast } from "react-toastify";
import Integrations from "./pages/integrations/Integrations";
import { ProSidebarProvider } from "react-pro-sidebar";
import en from "javascript-time-ago/locale/en.json";
import TimeAgo from "javascript-time-ago";
import "./style.css";
import DraggablePage from "./components/DragAndDrop/DragablePage";
import ChangePassword from "./components/auth/ChangePassword";
import ExploreData from "./pages/data/explore";
import AuditLogs from "./pages/auditlogs/AuditLogs";
import Dictionary from "./pages/dictionary/dictionary";
import DashboardAnalysis from "./components/DashboardAnalysis/DashboardAnalysis";
import ConnectData from "./pages/integrations/ConnectData";

TimeAgo.addDefaultLocale(en);

const queryClient = new QueryClient();

if (localStorage.getItem("token")) {
  // Set auth token header auth
  const token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export default function App() {
  useMemo(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          error.response?.status === 403 &&
          error.response.data?.error === "REQUIRES WRITE PERMISSION"
        ) {
          toast.error(
            "Sorry, you do not have write permissions - please speak to your account administrator",
            {
              toastId: "toast-error",
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 7500,
            }
          );
        }

        if (error.response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }

        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <ProSidebarProvider>
      <QueryClientProvider client={queryClient}>
        <ToastContainer position="top-right" autoClose={5000} />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          <Route
            path="connect-data"
            element={
              <AuthGuard>
                <ConnectData />
              </AuthGuard>
            }
          />
          <Route
            path="/change-password"
            element={
              <AuthGuard>
                <ChangePassword />
              </AuthGuard>
            }
          />

          <Route path="/conversations" element={<Conversations />} />

          <Route
            path="/"
            element={
              <AuthGuard isLayout={true}>
                <AppLayout />
              </AuthGuard>
            }
          >
            <Route
              index
              element={
                <AuthGuard>
                  <Home />
                </AuthGuard>
              }
            />

            <Route
              path="integrations"
              element={
                <AuthGuard>
                  <Integrations />
                </AuthGuard>
              }
            />
            <Route
              path="logs"
              element={
                <AuthGuard>
                  <AuditLogs />
                </AuthGuard>
              }
            />

            <Route
              path="projects"
              element={
                <AuthGuard>
                  <ProjectsPage />
                </AuthGuard>
              }
            />

            <Route
              path="draggable"
              element={
                <AuthGuard>
                  <DraggablePage />
                </AuthGuard>
              }
            />

            <Route
              path="team"
              element={
                <AuthGuard>
                  <TeamPage />
                </AuthGuard>
              }
            />
            <Route
              path="data"
              element={
                <AuthGuard>
                  <ExploreData />
                </AuthGuard>
              }
            />
            <Route
              path="dictionary"
              element={
                <AuthGuard>
                  <Dictionary />
                </AuthGuard>
              }
            />

            <Route
              path="liveboards"
              element={
                <AuthGuard>
                  <LiveBoardsPage />
                </AuthGuard>
              }
            />
            <Route
              path="dashboardAnalysis"
              element={
                <AuthGuard>
                  <DashboardAnalysis />
                </AuthGuard>
              }
            />
            <Route
              path="liveboardview"
              element={
                <AuthGuard>
                  <LiveBoardViewPage />
                </AuthGuard>
              }
            />
          </Route>
          <Route path="*" element={<Login />} />
        </Routes>
      </QueryClientProvider>
    </ProSidebarProvider>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
