import React, { useRef, useEffect } from "react";
import interact from "interactjs";

function DraggableResizableElement({
  children,
  id,
  style,
  parentWidth,
  parentHeight,
}) {
  const elementRef = useRef(null);
  const parentRef = useRef(null);
  const loadPreviousPosition = () => {
    const element = elementRef.current;
    if (localStorage.getItem("positions") == null) {
      return;
    }
    const positions = JSON.parse(localStorage.getItem("positions") ?? "{}");
    const dx = positions?.[id]?.["x"] ?? 0;
    const dy = positions?.[id]?.["y"] ?? 0;
    const width = positions?.[id]?.["width"] ?? "100px";
    const height = positions?.[id]?.["height"] ?? "100px";
    element.style.width = `${width}px`;
    element.style.height = `${height}px`;
    element.style.transform = `translate(${dx}px, ${dy}px)`;
    element.setAttribute("data-x", dx);
    element.setAttribute("data-y", dy);
    console.log("saved_corrd", id, dx, dy);
  };

  const updatePositionInStorage = (x, y, height, width) => {
    const positions = JSON.parse(localStorage.getItem("positions") ?? "{}");
    const oldHeight = positions[id]?.["height"] ?? "100px";
    const oldWidth = positions[id]?.["width"] ?? "100px";
    const oldX = positions[id]?.["x"] ?? 0;
    const oldY = positions[id]?.["y"] ?? 0;
    const updatedPosition = {
      ...positions,
      [id]: {
        x: x ?? oldX,
        y: y ?? oldY,
        height: height ?? oldHeight,
        width: width ?? oldWidth,
      },
    };
    console.log("updated", updatedPosition);
    localStorage.setItem("positions", JSON.stringify(updatedPosition));
  };

  useEffect(() => {
    const element = elementRef.current;

    loadPreviousPosition();
    interact(element)
      .resizable({
        edges: { left: true, right: true, bottom: true, top: true },
        listeners: {
          move: (event) => {
            const { width, height } = event.rect;
            element.style.width = `${width}px`;
            element.style.height = `${height}px`;
            updatePositionInStorage(null, null, height, width);
          },
        },
      })
      .draggable({
        restrict: {
          restriction: "#parent-div",
          elementRect: { top: 0, left: 0, bottom: -10, right: 1 },
        },
        onmove: (event) => {
          const { dx, dy } = event;
          const x = parseFloat(element.getAttribute("data-x")) || 0;
          const y = parseFloat(element.getAttribute("data-y")) || 0;
          element.style.transform = `translate(${x + dx}px, ${y + dy}px)`;
          element.setAttribute("data-x", x + dx);
          element.setAttribute("data-y", y + dy);
          updatePositionInStorage(x + dx, y + dy, null, null);
        },
      });

    return () => {
      interact(element).unset();
    };
  }, []);

  return (
    // <div
    //     ref={parentRef}
    //     style={{
    //         width: "100%",
    //         height: "100%",
    //         border: "1px solid white",
    //         position: "relative",
    //     }}
    // >
    <div
      id={id}
      style={{
        height: "400px",
        overflow: "auto",
        padding: "2rem",
        border: "1px solid #4a4a4a",
        marginBottom: "3rem",
        marginTop: "1rem",
        borderRadius: "8px",
        ...style,
      }}
      ref={elementRef}
    >
      {children}
    </div>
    // </div>
  );
}

export default DraggableResizableElement;
